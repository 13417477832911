import React from 'react';
import { Button } from 'antd';
import { SimpleModal } from '@/common/components/modal';

const AccessDeniedModal = ({ isVisible, onClose }) => {
  console.log('Rendering AccessDeniedModal, isVisible:', isVisible);

  const modalContent = (
    <div className="text-center text-white font-lekton">
      <h2 className="text-3xl font-bold mb-6">Access Denied</h2>
      <p className="text-xl mb-4">
        You do not have the required permissions to access this page.
      </p>
      <Button
        type="primary"
        onClick={onClose}
        className="text-xl px-8 py-3 h-auto bg-customRed hover:bg-customRed/80"
      >
        Close
      </Button>
    </div>
  );

  return (
    <SimpleModal
      open={isVisible}
      setModal={onClose}
      modalCnt={modalContent}
      width={500}
      footer={null}
      style={{
        top: 180,
        color: "white",
      }}
    />
  );
};

export default AccessDeniedModal;
