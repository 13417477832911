import OverviewBanner from '@/assets/img/products/overview_banner.png'
import DashboardIcon from '@/assets/img/products/dashboard_icon.svg'
import DashboardBanner from '@/assets/img/products/dashboard_banner.svg'
import DashboardBanner1 from '@/assets/img/products/dashboard_banner1.svg'
import DashboardBanner2 from '@/assets/img/products/dashboard_banner2.png'
import HiveIcon from '@/assets/img/products/hive_icon.svg'
import HiveBanner from '@/assets/img/products/hive_banner.svg'
import HiveBanner1 from '@/assets/img/products/hive_banner1.svg'
import HiveBanner2 from '@/assets/img/products/hive_banner2.png'
import NewsIcon from '@/assets/img/products/news_icon.svg'
import NewsBanner from '@/assets/img/products/news_banner.svg'
import NewsBanner1 from '@/assets/img/products/news_banner1.svg'
import NewsBanner2 from '@/assets/img/products/news_banner2.png'
import PulseIcon from '@/assets/img/products/pulse_icon.svg'
import PulseBanner from '@/assets/img/products/pulse_banner.svg'
import PulseBanner1 from '@/assets/img/products/pulse_banner1.svg'
import PulseBanner2 from '@/assets/img/products/pulse_banner2.png'
import WorkIcon from '@/assets/img/products/work_icon.svg'
import SwarmBanner from '@/assets/img/products/swarm_banner.svg'
import SwarmBanner1 from '@/assets/img/products/swarm_banner1.svg'
import SwarmBanner2 from '@/assets/img/products/swarm_banner2.png'
import GraphIcon from '@/assets/img/products/graph_icon.svg'
import GraphBanner from '@/assets/img/products/graph_banner.svg'
import GraphBanner1 from '@/assets/img/products/graph_banner1.svg'
import GraphBanner2 from '@/assets/img/products/graph_banner2.png'
// Pricing
import GuaranteeIcon1 from '@/assets/img/pricing/fl_guarantee1.png'
import GuaranteeIcon2 from '@/assets/img/pricing/fl_guarantee2.png'
import GuaranteeIcon3 from '@/assets/img/pricing/fl_guarantee3.png'
import FLBasicSpeedIcon from '@/assets/img/pricing/fl_basic_speed.svg'
import FLBasicIntelligenceIcon from '@/assets/img/pricing/fl_basic_intelligence.svg'
import FLBasicWorkoutIcon from '@/assets/img/pricing/fl_basic_workout.svg'
import FLBasicEfficiencyIcon from '@/assets/img/pricing/fl_basic_efficiency.png'

import FLProPremiumIcon from '@/assets/img/pricing/fl_pro_premium.svg'
import FLProEfficiencyIcon from '@/assets/img/pricing/fl_pro_efficiency.png'

import FLElitePremiumIcon from '@/assets/img/pricing/fl_elite_premium.svg'
import FLEliteEfficiencyIcon from '@/assets/img/pricing/fl_elite_efficiency.png'

// Solutions
import FLWriter from '@/assets/img/solutions/writer.svg'
import FLGraphicDesigner from '@/assets/img/solutions/graphic-designer.svg'
import FLSoftwareDevelopers from '@/assets/img/solutions/software-developers.svg'
import FLTutors from '@/assets/img/solutions/tutors.svg'
import FLVirtualAssistants from '@/assets/img/solutions/virtual-assistants.svg'
import FLConsultants from '@/assets/img/solutions/consultants.svg'
import FLTranslators from '@/assets/img/solutions/translators.svg'
import FLPhotographers from '@/assets/img/solutions/photographers.svg'
import FLDataAnalysts from '@/assets/img/solutions/data-analysts.svg'
import FLMarketingSpecialists from '@/assets/img/solutions/marketing-specialists.svg'
import FLAccountants from '@/assets/img/solutions/accountants.svg'
import FLJournalits from '@/assets/img/solutions/journalists.svg'
import FLTraders from '@/assets/img/solutions/traders.svg'
import FLSeoSpecialists from '@/assets/img/solutions/seo-specialists.svg'
import FLResearchers from '@/assets/img/solutions/researchers.svg'
import FLSocialMediaManager from '@/assets/img/solutions/social-media-manager.svg'
import FLLawyers from '@/assets/img/solutions/real-estate-agents.svg'
import FLPrCommSpecialists from '@/assets/img/solutions/pr-communication-specialists.svg'
import FLEntrepreneurs from '@/assets/img/solutions/entrepreneurs.svg'

// Freelancer Writers
import FLWritersIcon from '@/assets/img/solutions/freelancer/writers-icon.svg'
import FLWritersBanner from '@/assets/img/solutions/freelancer/writers-banner.svg'
import FLFeaturesBanner from '@/assets/img/solutions/freelancer/features_banner.svg'
import FLWritersChart from '@/assets/img/solutions/freelancer/chart.svg'
// Freelancer Graphic Designers
import FLGDesingersIcon from '@/assets/img/solutions/freelancer/g-designers-icon.svg'
import FLGDesignersBanner from '@/assets/img/solutions/freelancer/g-designers-banner.svg'
// Freelancer Software Developers
import FLSDevelopersIcon from '@/assets/img/solutions/freelancer/s-developers-icon.svg'
import FLSDevelopersBanner from '@/assets/img/solutions/freelancer/s-developers-banner.svg'
// Freelancer Tutors
import FLTutorsIcon from '@/assets/img/solutions/freelancer/tutors-icon.svg'
import FLTutorsBanner from '@/assets/img/solutions/freelancer/tutors-banner.svg'
// Freelancer Data Analysts
import FLDAnalystsIcon from '@/assets/img/solutions/freelancer/d-analysts-icon.svg'
import FLDAnalystsBanner from '@/assets/img/solutions/freelancer/d-analysts-banner.svg'
// Freelancer Marketing Specialists
import FLMSpecialistsIcon from '@/assets/img/solutions/freelancer/m-specialists-icon.svg'
import FLMSpecialistsBanner from '@/assets/img/solutions/freelancer/m-specialists-banner.svg'
// Freelancer Accountants
import FLAccountantsIcon from '@/assets/img/solutions/freelancer/accountants-icon.svg'
import FLAccountantsBanner from '@/assets/img/solutions/freelancer/accountants-banner.svg'
// Freelancer Journalists
import FLJournalistsIcon from '@/assets/img/solutions/freelancer/journalists-icon.svg'
import FLJournalistsBanner from '@/assets/img/solutions/freelancer/journalists-banner.svg'
// Freelancer Traders
import FLTradersIcon from '@/assets/img/solutions/freelancer/traders-icon.svg'
import FLTradersBanner from '@/assets/img/solutions/freelancer/traders-banner.svg'
// Freelancer SEO Specialist
import FLSSpecialistsIcon from '@/assets/img/solutions/freelancer/s-specialists-icon.svg'
import FLSSpecialistsBanner from '@/assets/img/solutions/freelancer/s-specialists-banner.svg'
// Freelancer Researchers
import FLResearchersIcon from '@/assets/img/solutions/freelancer/researchers-icon.svg'
import FLResearchersBanner from '@/assets/img/solutions/freelancer/researchers-banner.svg'
// Freelancer Social media manager
import FLSMManagerIcon from '@/assets/img/solutions/freelancer/s-m-manager-icon.svg'
import FLSMManagerBanner from '@/assets/img/solutions/freelancer/s-m-manager-banner.svg'
// Freelancer Assistants
import FLVAssistantsIcon from '@/assets/img/solutions/freelancer/v-assistants-icon.svg'
import FLVAssistantsBanner from '@/assets/img/solutions/freelancer/v-assistants-banner.svg'
// Freelancer Consultants
import FLConsultantsIcon from '@/assets/img/solutions/freelancer/consultants-icon.svg'
import FLConsultantsBanner from '@/assets/img/solutions/freelancer/consultants-banner.svg'
// Freelancer Translators
import FLTranslatorsIcon from '@/assets/img/solutions/freelancer/translators-icon.svg'
import FLTranslatorsBanner from '@/assets/img/solutions/freelancer/translators-banner.svg'
// Freelancer Photographers
import FLPhotographersIcon from '@/assets/img/solutions/freelancer/photographers-icon.svg'
import FLPhotographersBanner from '@/assets/img/solutions/freelancer/photographers-banner.svg'
// Freelancer Lawyers
import FLREAgentsIcon from '@/assets/img/solutions/freelancer/r-e-agents-icon.svg'
import FLREAgentsBanner from '@/assets/img/solutions/freelancer/r-e-agents-banner.svg'
// Freelancer PR & Communications specialists
import PRCSpecialistsIcon from '@/assets/img/solutions/freelancer/p-c-specialists-icon.svg'
import PRCSpecialistsBanner from '@/assets/img/solutions/freelancer/p-c-specialists-banner.svg'
// Freelancer Entrepreneurs
import EntrepreneursIcon from '@/assets/img/solutions/freelancer/entrepreneurs-icon.svg'
import EntrepreneursBanner from '@/assets/img/solutions/freelancer/entrepreneurs-banner.svg'

import FLEPlannersIcon from '@/assets/img/solutions/freelancer/e-planners-icon.svg'
import FLRecruitersIcon from '@/assets/img/solutions/freelancer/recruiters-icon.svg'
import FLTAgentsIcon from '@/assets/img/solutions/freelancer/t-agents-icon.svg'

export const productData = {
  aboutus: {
    title: 'About Us',
    description1:
      'Firesight is an autonomous work, research and media intelligence platform, built for the future of work. An advanced SaaS platform utilizing Artificial General Intelligence (AGI) to streamline and enhance nearly every facet of how you work. From productivity, job automation and content generation, to advanced research, media monitoring, news curation + more.',
    description2:
      'Firesight is a horizon driven Artificial General Intelligence (AGI) & media intelligence project that strives to empower those who use it, with tools to completely redefine: how work is done; what a human can achieve; how they track and monitor world events. Firesight may best be described, not as a project, but a movement towards the fundamental expansion of possibility within the digital realm.',
  },
  overview: {
    icon: '',
    title: 'Overview',
    description:
      'Firesight is an autonomous work, research and media intelligence platform, built for the future of work. An advanced SaaS platform utilizing Artificial General Intelligence (AGI) to streamline and enhance nearly every facet of how you work. From productivity, job automation and content generation, to advanced research, media monitoring, news curation + more.',
    banner: OverviewBanner,
    subsections: [
      {
        title:
          'A new era of AI-driven intelligence platforms',
        description:
          "Firesight is designed for the evolution of modern work. Whether it's increasing productivity, automating tasks, generating insightful content, performing deep-dive research, curating relevant news, or monitoring media, Firesight offers solutions tailored for the future of work that will transform and enhance your work dynamics, regardless of your industry & core competencies.",
      },
    ],
  },
  dashboard: {
    icon: DashboardIcon,
    title: 'Dashboard',
    description:
      'The dashboard is your personalized intelligence command centre, capable of dynamically adjusting to your unique needs and work patterns. It learns and evolves with you, offering the most relevant tools & information intuitively. Furthermore, it leverages our advanced briefing technology, turning the vast digital media landscape into easily digestible, personalized news briefings.',
    banner: DashboardBanner,
    subsections: [
      {
        title: 'stay ahead in the rapidly changing work environment',
        description:
          'Your ultimate one-stop solution for work, research, and media intelligence. Seamlessly access all your needed tools, insights, and data in one unified space, eliminating the hassle of switching between multiple platforms. Our Dashboard streamlines your workflow, boosts efficiency, and empowers you to take control of your day-to-day tasks with ease and precision.',
        banner: DashboardBanner1,
      },
      {
        title: 'your central hub for AI-powered news briefings',
        description:
          "Experience personalisation like never before. The Firesight Dashboard isn't just a static interface - it evolves and adapts to your work style. It learns from your habits and preferences, crafting a truly bespoke environment tailored to your unique needs. With our dashboard, it's not just about accessing tools, it's about creating a personalised, intuitive experience that helps you unlock maximum productivity. Navigate the future of work with Firesight",
        banner: DashboardBanner2,
      },
      {
        title: 'your central hub for AI-powered news briefings',
        description:
          'Our briefing technology sifts through the global noise of news, filtering out irrelevant information, misinformation and heavily biased opnion, delivering the essence of what truly matters to you. It summarises and contextualises crucial headlines and stories from around the world, giving you a concise, comprehensive briefing that keeps you informed without overwhelming you with excess.',
        banner: '',
      },
    ],
  },
  chat: {
    icon: HiveIcon,
    title: 'Chat',
    description:
      "A powerhouse of artificial intelligence that redefines interactive assistance. This ingenious chat interface, powered by the breakthrough GPT-4 technology, is your personal assistant, creative director, researcher, analyst, and scribe all rolled into one. It's plugged into an extensive network of plugins and third-party tools, enabling it to stay ahead of the curve and deliver up-to-the-minute information, on literally anything.",
    banner: HiveBanner,
    subsections: [
      {
        title: 'Step into the future with the ultimate information tool',
        description:
          'The AI Chat isn’t merely a communication platform; it’s a creative powerhouse that crafts stunning images, designs captivating graphics, and curates thorough reports and insightful studies. With access to real-time information and advanced plugins, AI Chat translates raw data into actionable intelligence, all within the comfort of your chat interface.',
        banner: HiveBanner1,
      },
      {
        title: 'Prompting technology taken to the next level',
        description:
          "Equipped with GPT-4, Firesight's AI Chat uses advanced 'chain of thought' and 'tree of thought' prompting techniques to understand context better, grasp subtleties, and offer more pertinent responses. Our AI Chat goes beyond providing answers; it comprehends and anticipates your needs, enhancing the quality of your interactions and the precision of your outcomes. Once you have the prompts taken care of, you can set and run ‘Smart Chats’ that provide customisable recurring updates to your queries, when underlying information changes.",
        banner: HiveBanner2,
      },
      {
        title: 'your conversations become the birthplace of productivity.',
        description:
          "Need a job done? Discuss it with our AI Chat and our autonomous AI agents will seamlessly take over, carrying out tasks with utmost efficiency and accuracy. Each interaction can spark a new assignment for our autonomous AI agents. Embrace the future of work with Firesight's AI Chat, where cutting-edge AI and seamless integration blend to navigate your path to success. Let AI Chat be your guide in an everchanging world.",
        banner: '',
      },
    ],
  },
  news: {
    icon: NewsIcon,
    title: 'News',
    description:
      'What if there was a place where every article, publisher, topic and narrative was covered and curated just for you. Firesight is that place. Specifically engineered for the instantaneous exploration of content you love, as well as the discovery of insights, trends and patterns that lie beneath the mainstream media surface. ',
    banner: NewsBanner,
    subsections: [
      {
        title: 'your personal gateway to global news intelligencE',
        description:
          'Gain a macroscopic view of narratives from every corner of the publishing world. Armed with  artificial general intelligence (AGI) technology, Firesight transcends the usual noise of news to offer a deeper understanding of global trends and current events. It expertly digests, summarises, and presents key topics, simplifying the flood of information and cutting through the clutter to provide you with the essence of the information - all via a platform that’s designed to deliver a personalised and tailored experience, intuitively adjusting to your interests and preferences.',
        banner: NewsBanner1,
      },
      {
        title: "Don't just read stories & news understand them",
        description:
          'Our platform absorbs and processes global news articles & headlines from an tens of thousands of publishing sources, allowing you to stay updated with stories & coverage that truly matter to you. Employing advanced AI algorithms, our state-of-the-art technology summarises and contextualises articles, stories and topics, delivering a deeper comprehension of global narratives for all your interests. Enjoy an entirely bespoke and insightful exploration of world events, all customised to your preferences.',
        banner: NewsBanner2,
      },
      {
        title: 'Trusted curation through non-biased AI generated editorial',
        description:
          'Firesight goes beyond being a news aggregator – it pioneers as a comprehensive news intelligence engine. Our state-of-the-art AGI technology not only delivers news from all the publishers you know and trust, but also crafts it. Leverage AI-generated articles that offer bias-free perspectives on unfolding events, delivering rich insights within an ad-free environment. Immerse yourself in the depth of understanding that Firesight’s News provides.',
        banner: '',
      },
    ],
  },
  pulse: {
    icon: PulseIcon,
    title: 'Pulse',
    description:
      'Feel the heartbeat of the global media landscape with our Media Intelligence and Surveillance region Pulse. Weaponising information through fast and accurate information feeds that populate a state-of-the-art user interface, offering you a deeper comprehension of current events and global trends.',
    banner: PulseBanner,
    subsections: [
      {
        title: 'Your interactive & immersive Gateway to Global Media Trends',
        description:
          "Interested in knowing which news topics are trending in a specific geographic area? Our media surveillance technology, scans the news universe and tracks the popularity of news topics across the globe, providing you with a pulse on the world's interest. Stay ahead of the curve, understand regional trends, and tailor your strategies with our highly interactive, geographical analysis features. our media intelligence engine will reshape your understanding of global narratives.",
        banner: PulseBanner1,
      },
      {
        title: 'past, present & future of media in the palm of your hand',
        description:
          'Imagine absorbing 95% of news content in just 5% of the time, through concise, bias-free, fact-driven news summaries. Reflect on overarching trends in media narratives over a giant 365-day period. Experience the present through real-time buzz, trend, hype monitoring, backed by insightful metrics and state-of-the-art visualizations. Even harness the future by utilizing our media surveillance tools: keyword, phrase and narrative tracking, all paired with alert systems. Media Signals encapsulates the evolution, current state, and anticipated transformation of media.',
        banner: PulseBanner2,
      },
      {
        title: 'convert information to wisdom in a truly personalised setting',
        description:
          'Blending guided media intelligence, which zeroes in on the topics we discern as important to you, with self-guided media intelligence where you have the reins to explore your preferred industry or subject matter. Pulse, takes you beneath the headlines, away from the distractions. It offers a focused environment for real-time awareness and accelerated learning.',
        banner: '',
      },
    ],
  },
  work: {
    icon: WorkIcon,
    title: 'Work',
    description:
      'The pioneering autonomous work region within the Firesight Platform. This is where AI agents tirelessly work to execute tasks automatically, handling a diverse range of jobs. From data analysis, market research studies to content generation. Scheduling, writing code scripts to project management, Swarm is a testament to the powerful future of automated work.',
    banner: SwarmBanner,
    subsections: [
      {
        title: 'An Easy to use Army of AI Agents at Your Service',
        description:
          "Unleash the potential of automation with Work, where a battalion of AI agents stand ready to undertake your tasks. Whether it's processing vast amounts of data, generating reports & content, managing social media accounts, or executing an inexhaustible array of complex projects, our AI agents carry out tasks swiftly and efficiently. This automated workforce, equipped with cutting-edge AGI technology, can navigate and manage an impressive array of tasks, freeing up your time and resources.",
        banner: SwarmBanner1,
      },
      {
        title: 'We’re radicalising work as you know it',
        description:
          'Work takes autonomy to the next level. Our AI agents are not only tasked with completing work – they independently manage and optimise processes to boost productivity. These intelligent agents adapt and learn from each job, continuously evolving their skills and improving their performance. Acting with Work intelligence and possessing access to the worlds supply of information & data. Work represents the complete radicalisation of work as we know it.',
        banner: SwarmBanner2,
      },
      {
        title: 'embrace the power of versatile, scalable, autonomous work.',
        description:
          "Capable of handling a wide variety of jobs, our AI agents flexibly adapt to your needs. As your business grows and your tasks evolve, Work scales with you. Whether it's handling increasing data, managing more complex projects, or meeting new challenges, Work's scalable solutions are designed to support your growth and ensure continued success. Experience the future of work with Swarm, where automation, intelligence, and versatility come together to redefine productivity.",
        banner: '',
      },
    ],
  },
  graph: {
    icon: GraphIcon,
    title: 'Graph',
    description:
      'Graph serves as an interactive knowledge graph where users can visually comprehend, interact with and control information layouts. With a seamless blend of user control and smart prompt recommendations, Graph ensures an organised and intuitive approach to information interaction.',
    banner: GraphBanner,
    subsections: [
      {
        title: 'Immerse yourself in the world of visual knowledge exploration',
        description:
          'Graph ensures that your quest for knowledge remains structured and intuitive, without ever becoming overwhelming. This unique interface offers the power to visually navigate and interact with the wealth of information at your disposal. Enjoy the freedom to customize the layout, intuitively drag and drop nodes, and effortlessly switch between graphical and text view for each node. With this engaging and flexible way of representing knowledge, your understanding of complex information is enhanced, making interactions insightful and enjoyable.',
        banner: GraphBanner1,
      },
      {
        title: 'Experience the future of information interaction with Graph',
        description:
          "Navigating complex topics & information has never been easier. With Graph, you're never alone on your journey. Our interface provides an array of smart query prompts to guide your process, ensuring your interactions are streamlined and effective. Experience the perfect blend of autonomy and intelligent assistance, making your information exploration both intuitive and insightful.",
        banner: GraphBanner2,
      },
      {
        title: 'keep your mind free from information overload',
        description:
          "Even when dealing with complex information sets, Graph's smart design ensures information is delivered in digestible chunks. This way, you can delve deeper into the information without getting lost in the details, maintaining clarity and focus throughout. Embark on your journey of knowledge discovery, enjoying the blend of interactive visualisation, smart guidance, and an organised, overwhelm-free interface. ",
        banner: '',
      },
    ],
  },
}

const basicPricing = {
  title: 'Basic',
  monthlyFee: '$49.99',
  speed: FLBasicSpeedIcon,
  intelligence: FLBasicIntelligenceIcon,
  workOutput: FLBasicWorkoutIcon,
  seatLimit: 99,
  currentLimit: 0,
  guarantee: {
    description:
      "In conjunction with our partner brand: <b style='font-weight: 700'>D’Vinci Agency</b> (dvinci.io) we’re excited to offer:",
    guarantees: [
      {
        icon: GuaranteeIcon1,
        description:
          'Guaranteed Entry to the exclusive D’Vinci Agency, seriously enhancing your professional and work opportunities.',
      },
      {
        icon: GuaranteeIcon2,
        description:
          "25+ targeted job proposals sent out on your behalf by the D’Vinci Business Development Team, on <b style='font-weight: 700'>Upwork.com</b> & <b style='font-weight: 700'>Freelancer.com</b> (for every month you subscribe.)",
      },
      {
        icon: GuaranteeIcon3,
        description:
          "<b style='font-weight: 700'>Work guarantee:</b> if we dont you a paying job in any month, you receive a <b style='font-weight: 700'>50% discount</b> on your monthly subscription, for that month.",
      },
    ],
  },
  efficiency: {
    icon: FLBasicEfficiencyIcon,
    efficiencies: [
      {
        description: 'Access to all Standard Modes',
      },
      {
        description: "2 Concurrent <b style='font-weight: 700'>Work</b> Agents",
      },
      {
        description: '1GB Storage Allotment',
      },
      {
        description:
          "5 Concurrent <b style='font-weight: 700'>Chat</b> Smart Chats ",
      },
      {
        description:
          "5 Concurrent <b style='font-weight: 700'>Pulse</b> Keyword Tracking or Narrative Tracking Searches ",
      },
      {
        description:
          'All subscriptions provide full access to all Product Regions of Firesight.ai on our web platform',
      },
    ],
  },
  credits: {
    number: 10000,
    description:
      '<b>Credits explained:</b> Each subscription has a base credit allocation. Credits are consumed through compute power + API calls to 3rd-Party intelligence providers, such as Open AI (GPT-4 API).',
  },
}

const proPricing = {
  title: 'Pro',
  monthlyFee: '$99.99',
  speed: FLProPremiumIcon,
  intelligence: FLProPremiumIcon,
  workOutput: FLProPremiumIcon,
  seatLimit: 99,
  currentLimit: 0,
  guarantee: {
    description:
      "In conjunction with our partner brand: <b style='font-weight: 700'>D’Vinci Agency</b> (dvinci.io) we’re excited to offer:",
    guarantees: [
      {
        icon: GuaranteeIcon1,
        description:
          'Guaranteed Entry to the exclusive D’Vinci Agency, seriously enhancing your professional and work opportunities.',
      },
      {
        icon: GuaranteeIcon2,
        description:
          "50+ targeted job proposals sent out on your behalf by the D’Vinci Business Development Team, on <b style='font-weight: 700'>Upwork.com</b> & <b style='font-weight: 700'>Freelancer.com</b> (for every month you subscribe.)",
      },
      {
        icon: GuaranteeIcon3,
        description:
          "<b style='font-weight: 700'>Work guarantee:</b> if we dont you a paying job in any month, you receive a <b style='font-weight: 700'>50% discount</b> on your monthly subscription, for that month.",
      },
    ],
  },
  efficiency: {
    icon: FLProEfficiencyIcon,
    efficiencies: [
      {
        description:
          'Access to Standard & Premium Modes (bringing job-specific benefits)',
      },
      {
        description: '10 Concurrent Work Agents',
      },
      {
        description: '10GB Storage Allotment',
      },
      {
        description:
          "50 Concurrent <b style='font-weight: 700'>Chat</b> Smart Chats",
      },
      {
        description:
          "50 Concurrent <b style='font-weight: 700'>Pulse</b> Keyword Tracking or Narrative Tracking Searches ",
      },
      {
        description:
          'CoT (Chain of Thought) and ToT (Tree of Though) Prompt Access',
      },
      {
        description:
          'All subscriptions provide full access to all Product Regions of Firesight.ai on our web platform',
      },
    ],
  },
  credits: {
    number: 10000,
    description:
      '<b>Credits explained:</b> Each subscription has a base credit allocation. Credits are consumed through compute power + API calls to 3rd-Party intelligence providers, such as Open AI (GPT-4 API).',
  },
}

const elitePricing = {
  title: 'Elite',
  monthlyFee: '$49.99',
  speed: FLElitePremiumIcon,
  intelligence: FLElitePremiumIcon,
  workOutput: FLElitePremiumIcon,
  seatLimit: 99,
  currentLimit: 0,
  guarantee: {
    description:
      "In conjunction with our partner brand: <b style='font-weight: 700'>D’Vinci Agency</b> (dvinci.io) we’re excited to offer:",
    guarantees: [
      {
        icon: GuaranteeIcon1,
        description:
          'Guaranteed Entry to the exclusive D’Vinci Agency, seriously enhancing your professional and work opportunities.',
      },
      {
        icon: GuaranteeIcon2,
        description:
          "75+ targeted job proposals sent out on your behalf by the D’Vinci Business Development Team, on <b style='font-weight: 700'>Upwork.com</b> & <b style='font-weight: 700'>Freelancer.com</b> (for every month you subscribe.)",
      },
      {
        icon: GuaranteeIcon3,
        description:
          "<b style='font-weight: 700'>Work guarantee:</b> if we dont you a paying job in any month, you receive a <b style='font-weight: 700'>50% discount</b> on your monthly subscription, for that month.",
      },
    ],
  },
  efficiency: {
    icon: FLEliteEfficiencyIcon,
    efficiencies: [
      {
        description:
          'Access to Standard & Premium Modes (bringing job-specific benefits)',
      },
      {
        description: '100 Concurrent Work Agents',
      },
      {
        description: '75GB Storage Allotment  ',
      },
      {
        description:
          "Unlimited Concurrent <b style='font-weight: 700'>Chat</b> Smart Chats ",
      },
      {
        description:
          "Unlimited Concurrent <b style='font-weight: 700'>Pulse</b> Keyword Tracking or Narrative Tracking Searches ",
      },
      {
        description:
          'CoT (Chain of Thought) and ToT (Tree of Though) Prompt Access',
      },
      {
        description:
          'All subscriptions provide full access to all Product Regions of Firesight.ai on our web platform',
      },
    ],
  },
  credits: {
    number: 250000,
    description:
      '<b>Credits explained:</b> Each subscription has a base credit allocation. Credits are consumed through compute power + API calls to 3rd-Party intelligence providers, such as Open AI (GPT-4 API).',
  },
}

export const pricingData = {
  title: 'Pricing',
  description:
    'Experience unprecedented efficiency, productivity, and intelligence, all powered by our ground breaking formative AGI technology. Automate work processes, optimise business decision-making, or harness the worlds supply of information through our intuitive platform.\n\nFiresight will have you saying goodbye to mundane work flows and hello to a smarter, more efficient future.',
  tabData: {
    freelancer: {
      title: 'Freelancer',
      plans: {
        basic: basicPricing,
        pro: proPricing,
        elite: elitePricing,
      },
    },
    startup: {
      title: 'Startup',
      plans: {
        basic: basicPricing,
        pro: proPricing,
        elite: elitePricing,
      },
    },
    agency: {
      title: 'Agency',
      plans: {
        basic: basicPricing,
        pro: proPricing,
        elite: elitePricing,
      },
    },
    enterprise: {
      title: 'Enterprise',
      plans: {
        basic: basicPricing,
        pro: proPricing,
        elite: elitePricing,
      },
    },
  },
}

export const solutionsData = {
  title: 'Solutions',
  tabData: {
    freelancer: {
      title: 'Freelancer',
      category: [
        {
          icon: FLWriter,
          title: 'Writers',
          url: 'fl_writers',
        },
        {
          icon: FLGraphicDesigner,
          title: 'Graphic Designers',
          url: 'fl_graphicdesigners',
        },
        {
          icon: FLSoftwareDevelopers,
          title: 'Software Developers',
          url: 'fl_softwaredevelopers',
        },
        {
          icon: FLTutors,
          title: 'Tutors',
          url: 'fl_tutors',
        },
        {
          icon: FLDataAnalysts,
          title: 'Data Analysts',
          url: 'fl_dataanalysts',
        },
        {
          icon: FLMarketingSpecialists,
          title: 'Marketing Specialists',
          url: 'fl_marketingspecialists',
        },
        {
          icon: FLAccountants,
          title: 'Accountants',
          url: 'fl_accountants',
        },
        {
          icon: FLJournalits,
          title: 'Journalists',
          url: 'fl_journalists',
        },
        {
          icon: FLTraders,
          title: 'Traders',
          url: 'fl_traders',
        },
        {
          icon: FLSeoSpecialists,
          title: 'SEO Specialists',
          url: 'fl_seospecialists',
        },
        {
          icon: FLResearchers,
          title: 'Researchers',
          url: 'fl_researchers',
        },
        {
          icon: FLSocialMediaManager,
          title: 'Social Media Manager',
          url: 'fl_socialmediamanager',
        },
        {
          icon: FLVirtualAssistants,
          title: 'Assistants',
          url: 'fl_virtualassistants',
        },
        {
          icon: FLConsultants,
          title: 'Consultants',
          url: 'fl_consultants',
        },
        {
          icon: FLTranslators,
          title: 'Translators',
          url: 'fl_translators',
        },
        {
          icon: FLPhotographers,
          title: 'Photographers',
          url: 'fl_photographers',
        },
        {
          icon: FLLawyers,
          title: 'Lawyers',
          url: 'fl_lawyers',
        },
        {
          icon: FLPrCommSpecialists,
          title: 'PR & Communications Specialists',
          url: 'fl_prcomspecialists',
        },
        {
          icon: FLEntrepreneurs,
          title: 'Entrepreneurs',
          url: 'fl_entrepreneurs',
        },
        // {
        //   icon: FLEventPlanners,
        //   title: "Event Planners",
        //   url: "fl_eventplanners",
        // },
        // {
        //   icon: FLRecruiters,
        //   title: "Recruiters",
        //   url: "fl_recruiters",
        // },
        // {
        //   icon: FLTravelAgents,
        //   title: "Travel Agents",
        //   url: "fl_travelagents",
        // },
      ],
      subpages: {
        fl_writers: {
          name: 'Writers',
          icon: FLWritersIcon,
          section1: {
            title:
              'Stay Ahead with Unrivalled News Intelligence and Discover Untapped Content Niches',
            descriptions:
              "Staying relevant and keeping your content fresh is crucial in the world of professional writing. As a freelancer or independent professional, Firesight's 'News' region equips you with an incredibly intelligent news feed, offering a macroscopic view of narratives from every corner of the publishing world. Imagine having the power to cut through the noise and directly reach the essence of the information. We simplify the flood of global news, summarising and contextualising articles and stories from tens of thousands of publishing sources, providing you with insights into topics that truly matter to you. Spot untapped content niches, uncover hidden trends, and develop unique perspectives with Firesight.",
          },
          integrations: {
            banner: FLWritersBanner,
            title:
              'Harness the Power of Media Surveillance to Amplify Your Writing Impact.',
            descriptions: [
              "Every professional writer wants their work to make an impact. Our 'Pulse' region, a state-of-the-art media intelligence engine, powered by natural language understanding (NLU), generation (NLG) and processing (NLP) technologies. brings you closer to that goal by providing you with a heartbeat of the global media landscape. You can track trending news topics in specific geographic areas or industries, understand regional trends, analyse sentiment, even track hype for any topic through media mentions intelligence. This valuable insight helps you stay ahead of the curve, maximising the resonance of your content with your target audience. Furthermore, concise, bias-free news summaries let you absorb 95% of content in just 5% of the time, leaving you more time to focus on creating exceptional pieces.",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title: 'Optimise Your Productivity with Autonomous Work Agents',
            descriptions: [
              "As a writer, your craft demands a high degree of focus and mental energy. 'Work', our pioneering autonomous work region that utilises state-of-the-art generative AI and Large Language Models (LLMs) offloads administrative tasks and lets you devote more time to what you do best - writing. From data analysis, research, scheduling, to managing social media accounts, our AGI-powered AI agents are capable of handling a wide variety of tasks, liberating you from distractions and time-consuming chores. You can also have our agents generate reports and content to provide preliminary drafts or background information for your writing assignments. Swarm is not just about automation – it is about optimising your productivity, so you can focus on delivering your best work.",
            ],
          },
          emphasize: {
            title:
              'Enhance Your Research Process with Interactive Knowledge Graphs and advanced AI Chats',
            descriptions: [
              "'Graph' and 'Chat', two powerful regions of Firesight, revolutionise the research aspect of your writing process. Graph offers an interactive knowledge graph, driven by AI Chat, enabling you to visually interact with complex information, facilitated by breakthroughs in natural language technology. Meanwhile, Chat, our smart AI Chat powered by GPT-4, LLMs and countless other integrations including search engine access, serve as your personal assistant, researcher, and analyst. It uses 'chain of thought (CoT)' and 'tree of thought (ToT)' prompting techniques to understand context and provide highly insightful responses. With Firesight, your research is more efficient, and your narratives more captivating, all driven by the power of AI and advanced natural language technologies.",

              'Welcome to Firesight, the AI-powered autonomous work, research and media intelligence platform where technology serves your needs, empowers your creativity, and optimises your success in professional writing, letting your writing shine like never before.',
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_graphicdesigners: {
          name: 'Graphic Designers',
          icon: FLGDesingersIcon,
          section1: {
            title: 'Enhance Your Creativity and Augment Your Artistry with AI',
            description:
              "As a graphic designer, your creativity is your most valuable asset. But what if you could enhance that creativity, speeding up your workflow, exploring new design paths, and eliminating the mundane aspects of your job? Enter Firesight.ai's 'Work' – the autonomous work region, where AI agents tirelessly work to execute tasks automatically, from data analysis to content generation. Imagine the power of AI designing logos based on your brief or creating alternative versions of your designs. Imagine AI suggesting the perfect colour palette or identifying trending design styles. By eliminating time-consuming elements of your job, you can focus on what you do best: being creative.",
          },
          integrations: {
            banner: FLGDesignersBanner,
            title:
              'The Future at Your Fingertips: Tailored Insights and Trends',
            descriptions: [
              "In the fast-paced world of graphic design, staying ahead of the curve is more important than ever. Firesight.ai's 'News' and 'Pulse' regions offer you a lifeline to the heartbeat of the global design landscape. From personalised news feeds to in-depth trend analysis, you're provided with a panoramic view of the design world. Interested in the latest digital illustration trends in Japan? Or perhaps you want to explore popular typography styles in the US? Firesight.ai's media surveillance technology is your lens into global design trends, all tailored to your unique interests and needs. You can observe patterns, forecast trends and gain unique insights that set your designs apart from the crowd.",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title:
              'Firesight will Transform Your Workflow with Autonomous AI Technology',
            descriptions: [
              "As a freelancer, the reality of managing multiple clients, deadlines, and budgets can often overshadow your creative process. But what if you could delegate these tasks to a reliable, efficient assistant? The 'Work' region in Firesight.ai can be that assistant, taking over the administrative side of your business. This region is armed with AI agents adept at managing your schedules, tracking project timelines, and overseeing various tasks. Imagine having your very own project manager, who never sleeps, never forgets, and is always on top of your work. With Work, you can be a master of both your craft and your time.",
            ],
          },
          emphasize: {
            title: 'Expand Your Creative Universe: Knowledge at Your Command',
            descriptions: [
              "The world of graphic design is always evolving, with new tools, techniques, and trends emerging all the time. Staying up-to-date and mastering new skills can be a challenge, especially when you're busy with client work. Firesight.ai's 'Graph' region serves as an interactive knowledge graph where you can visually comprehend, interact with, and control information layouts. Whether you're learning a new design software or deep-diving into the psychology of colour, Graph makes your learning process intuitive and engaging.",

              'Each of these benefits – from time-saving automation to trend-spotting intelligence, from project management tools to interactive learning – all serve to amplify your creative abilities, freeing you from mundane tasks, and giving you the space to do what you love: creating amazing designs. Experience the future of graphic design with Firesight.ai.',
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_softwaredevelopers: {
          name: 'Software Developers',
          icon: FLSDevelopersIcon,
          section1: {
            title:
              'Upgrade Your Programming Efficiency with an AI-Powered sidekick',
            descriptions:
              "Software development is an art of solving complex problems, where the beauty of an elegant solution is matched by the satisfaction of flawless function. With the help of neural networks, our SaaS platform 'Work' region becomes your ever-ready coding companion, capable of formulating solutions and executing them autonomously. Work, armed with advanced natural language processing (NLP) and Generative AI technology, provides real-time assistance in debugging, code generation, and even optimising your scripts 24/7 and pushing to Github. Leapfrog the challenges of traditional software development processes, and build and deploy software that's smarter, faster, and efficient.",
          },
          integrations: {
            banner: FLSDevelopersBanner,
            title: 'Stay Ahead of Tech Trends:\nwith media surveillance',
            descriptions: [
              "Imagine absorbing 95% of tech news content in just 5% of the time, through concise, bias-free, fact-driven news summaries. No more wading through multiple sources or missing out on crucial updates. The 'News' region of our SaaS platform delivers just this. It curates a tailored feed of the latest trends and breakthrough technologies relevant to your work, giving you an edge in decision-making. The media intelligence focused 'Pulse' region adds an extra layer to this. Taking you beneath the headlines, providing real-time awareness and learning. Using natural language technology (NLG, NLU, NLP) Pulse offers geographical analysis & sentiment analysis of software development trends. With Firesight.ai, get the power of concise, bias-free, fact-driven tech news summaries, get real-time information on buzz, trends, and updates in the software development world, tailored to your exacting preferences. Firesight.ai's 'News' and 'Pulse' regions provide you with the information edge you need in a competitive landscape.",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title: 'Revolutionise Your Coding with Intelligent Automation',
            descriptions: [
              'Writing clean, effective code is a craft, and even the best coders can be slowed down by mundane tasks. The ‘Work’ region of Firesight, is one of the worlds first artificial general intelligence (AGI) systems provides intelligent code suggestions, automated debugging, writing code scripts, even monitor software in real-time to detect and fix issues autonomously. By offloading repetitive tasks to the ‘Work’ region of our SaaS platform, you can focus on problem-solving and strategic thinking. Moreover, the ‘Work’ regions advanced automation features work in tandem with our state-of-the-art LLMs & Generative AI, perfect for analysing complex project requirements, suggesting the best architectural or design patterns to apply, then producing technical documentation. Deliver projects faster and more efficiently, experience a new, dynamic way of programming that accelerates your workflow and drives project success.',
            ],
          },
          emphasize: {
            title:
              'Leverage Your Personalised Intelligence Command Centre: The Dashboard',
            descriptions: [
              "Chat' is a state-of-the-art AI chat region, functioning as your personal assistant, researcher, and analyst, all in one. Chat comprehends and anticipates your needs, helping you debug, issue track, design software more efficiently, or anything else you need help with! Moreover, with Chat's sophisticated NLP, NLU, and NLG abilities, it understands the language of software developers, making interactions more intuitive and effective. The 'Graph' region comes into play perfectly, when dealing with complex software architecture. With an interactive knowledge graph interface and an AI Chat engine, it provides a visually driven interaction hub to explore and manipulate information.",
              "The'Dashboard' region is a personalised intelligence command centre tailored to your unique needs and work patterns. It integrates features from the AI Chat centric regions ‘Chat’ & ‘Graph’ and other regions to present you with the most relevant tools, insights, and data in one unified space. Leverage our news briefing technology to keep abreast of every nuance and trend in the tech landscape, customisable to be as unique as your fingerprint. By merging your workflow, enhancing efficiency, and empowering you with an in-depth understanding of global narratives, Firesight's Dashboard is the epitome of software development support. It is a testament to the harmony of AI, neural networks, interactive visualisation, and natural language understanding. With Firesight, you're not just using a tool; you're embracing the future of software development",
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_tutors: {
          name: 'Tutors',
          icon: FLTutorsIcon,
          section1: {
            title: 'Elevate Your Tutoring with AI-Powered Personalisation',
            descriptions:
              "In the 21st-century classroom, the role of the tutor has evolved beyond merely imparting knowledge. It now involves understanding the unique learning styles of your students, tailoring curricula, and personalising teaching methods to foster deeper understanding. Through our 'Work' region, mundane administrative tasks such as scheduling, grading, and content creation are efficiently automated. Our AI agents utilise artificial general intelligence (AGI) and natural language understanding (NLU) technology to adapt to your teaching style, leaving you with more time to focus on your students. With the 'Chat' region, you can communicate with our AI chat that understands educational jargon. Need to clarify complex concepts or generate engaging lesson plans? Let our AI chat assist you can focus on what you do best - delivering impactful learning experiences.",
          },
          integrations: {
            banner: FLTutorsBanner,
            title:
              'Tune Into Education Trends: Stay Ahead in the Learning Curve',
            descriptions: [
              "To remain effective in today's rapidly changing educational landscape, staying abreast of the latest teaching trends, tools, and techniques is crucial. Firesight.ai's 'News' and 'Pulse' regions, powered by natural language technology (NLP; NLU; NLG) keep you connected with the global education community. These regions offer: personalised news feed, real-time trend analysis, media mentions analysis, AI Journalism, state-of-the-art sentiment analysis, story summaries, plus so much more. Gain in-depth views of shifts in the educational landscape - all customised to your interests, needs, even location. Whether you're interested in the latest findings in cognitive science or curious about new edtech tools, ‘News’ & ‘Pulse’ regions of Firesight have you covered.",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title:
              'The Invisible Teaching Assistant: Harness AI for Seamless Administration',
            descriptions: [
              'As a tutor, managing lesson schedules, tracking student progress, and handling administrative tasks can be a time-consuming endeavour. Imagine an assistant, powered by artificial general intelligence (AGI), who could take over these tasks and many more. Take your tutoring practice to new heights with ‘Work’ the autonomous work region of Firesight that revolutionises how you manage your day-to-day activities - leaving you more time to focus on your students. Grading assessments, scheduling sessions, drafting lesson plans, and preparing educational materials can all be managed by Work’s AI agents, processing vast amounts of information and executing complex projects while you focus on your core teaching duties. With Work, not only will you gain more time to devote to your students, but you will also be able to provide a more personalised and dynamic learning experience for each one. Embrace the future of tutoring with Work, where artificial intelligence meets the passion of education.',
            ],
          },
          emphasize: {
            title:
              'Lifelong Learning Made Easy: AI-Powered Knowledge Enhancement',
            descriptions: [
              "In a field as dynamic as education, professional development is crucial. There's always something new to learn - a new teaching method, a change in the curriculum, or a breakthrough in educational psychology. The 'Graph' region in Firesight.ai, is your personal learning coach, helping you to navigate through complex topics and stay updated. Its interactive knowledge graph interface, makes learning immersive and engaging. With Graph, professional development becomes a seamless part of your daily routine. The Graph region offers an innovative way to visually organise and present information. It's perfect for tutors who need to explain complex concepts or create clear, intuitive diagrams and mind maps for their students. It offers you the freedom to customise layouts, drag and drop nodes, and switch between graphical and textual views, creating an engaging and effective learning experience for your students.",
              'Firesight.ai offers tutors like you a suite of powerful AI tools to enhance your teaching, stay updated with the latest trends, streamline your administration, and simplify your learning. Take the leap into the future of tutoring with Firesight.ai, your comprehensive educational sidekick.',
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_virtualassistants: {
          name: 'Assistants',
          icon: FLVAssistantsIcon,
          section1: {
            title:
              'Revolutionise Your Assistant Role <br/> with your own autonomous workforce',
            description:
              "As an assistant, your role is diverse, multifaceted, and ever-evolving. You're often tasked with juggling numerous responsibilities simultaneously, which can be a daunting feat. Here's where Firesight's advanced AI technology comes into play, offering a platform to elevate your productivity to levels never seen before. Our 'Work' region serves as your tireless automated workforce. With the strength of one of the worlds first artificial general intelligence (AGI) systems, Work efficiently handles a vast array of tasks, from managing schedules to drafting correspondences, data analysis to project management. Imagine the advantage of delegating routine tasks to reliable AI agents, freeing up your time to focus on high-level, strategic responsibilities.",
          },
          integrations: {
            banner: FLVAssistantsBanner,
            title: 'Stay Informed with Media Surveillance and AI Journalism',
            descriptions: [
              "An assistant's role often involves keeping an eye on developments relevant to your organisation. Our 'Pulse' region, equipped with AI technology, offers a sophisticated media surveillance platform. It tracks trending topics and news that are of interest to your industry, enabling you to stay updated. AI journalism presents bias-free perspectives on unfolding events and trends, allowing you to brief your team effectively. By understanding narrative tracking, sentiment analysis, and media surveillance, you can anticipate changes and prepare accordingly.",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title: 'Command, Communicate, and Conquer Your Workload',
            descriptions: [
              "The 'Chat' region, our intelligent AI chat interface, which leverages the breakthrough GPT-4 technology. Chat understands your professional lingo, making it an efficient tool to communicate tasks to Work's AI agents. It's capable of interpreting context better, grasping subtleties, and providing more pertinent responses using natural language understanding (NLU). This interaction can spark new assignments for our AI agents, turning your commands into actions seamlessly. Need a last-minute job done? Discuss it with Chat, and our AI agents will take over, carrying out tasks swiftly, accurately, and autonomously. Chat's ability to use advanced 'chain of thought' and 'tree of thought' prompting techniques allows it to comprehend and anticipate your needs, thereby enhancing your interactions and the precision of your outcomes. With Firesight's cutting-edge AI technology, you can command an efficient workforce right at your fingertips, revolutionising the way you work and skyrocketing your productivity.",
            ],
          },
          emphasize: {
            title:
              'Master Task Management with a Personalised Intelligence Dashboard',
            descriptions: [
              "Firesight's 'Dashboard' is your personalised command centre. It dynamically adjusts to your work patterns, offering the most relevant tools and information intuitively. Whether it's managing your executive's calendar, keeping track of projects, or staying updated on industry trends, the dashboard helps you maintain control over all aspects of your job. Our news briefing technology turns vast amounts of information into easily digestible, personalised briefings. Stay updated on the latest news, deadlines, and tasks all in one place. It employs advanced NLU and NLG technologies to understand and generate content related to your responsibilities, streamlining your workflow and boosting your productivity.",
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_consultants: {
          name: 'Consultants',
          icon: FLConsultantsIcon,
          section1: {
            title:
              'Turbocharge your consulting output <br/> with AI driven automation',
            description:
              "As a consultant, your expertise and strategic insight are your most valuable assets. Firesight's Work region helps you make the most of these assets by taking over your routine tasks and digital jobs. Imagine having an intelligent assistant that can handle everything from data analysis and market research to report or document generation - freeing you up to focus on your strategic advisory role. Work's autonomous AI agents work tirelessly to execute tasks with accuracy and speed, making your work faster and more efficient. Whether it's processing vast amounts of information or generating detailed reports, Work ensures these tasks are completed swiftly, enabling you to deliver faster results to your clients. In the world of consultancy, where time is money and insights are the key to success, Firesight's Work offers a game-changing solution.",
          },
          integrations: {
            banner: FLConsultantsBanner,
            title:
              'fast-track information to knowledge with Real-Time   Media Analysis',
            descriptions: [
              "Firesight's Pulse region is designed to keep consultants like you at the forefront of current events and global trends. Pulse is more than just a media intelligence tool. It's your personal analyst, working around the clock to track topics of interest across the globe. You'll have your finger on the pulse of the world's media, understanding shifts in narratives and key trends that could impact your clients' businesses. With Pulse, you can analyse regional trends, monitor real-time buzz, sentiment analysis, geo-trends, track hype and dive into breaking story summaries without the bias. With advanced natural language understanding (NLU) technology, you can even understand overarching story narratives derived from published articles.",
              "Observe and interpret the shifts in market dynamics, public opinion, or regulatory changes, that are so often hidden in the news. You won't just be reacting to the market; you'll be predicting it. Pulse, with its advanced media surveillance tools, helps you stay a step ahead, arming you with insights that will position you as a thought leader in your field.",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title: 'Master information with Interactive Knowledge Mapping',
            descriptions: [
              "As a consultant, your ability to access, understand, and utilise information effectively is crucial. With Firesight's Chat and Graph regions, your information retrieval and comprehension processes are taken to the next level. Chat, our smart AI chat region, is like having a dedicated team of analysts and researchers at your disposal. Powered by GPT-4 technology and a multitude of integrations (e.g. Google), Chat is equipped with 'chain of thought' and 'tree of thought' prompting techniques, enabling it to provide precise and pertinent responses. Need a data report prepared, market research conducted, or complex information distilled into easy-to-understand formats? Discuss it with Chat and our autonomous AI agents seamlessly take over and ship the chat to the ‘Work’ region of the SaaS Platform, where the autonomous work is done. ",
              "Complementing Chat's capabilities, Graph, our interactive knowledge region, revolutionises your information interaction. Visualising complex data sets, Graph provides a clear, organised structure for knowledge comprehension. Navigate intricate industry trends, understand intertwined market relationships, and uncover hidden insights, all presented in a clear, digestible format. Graph is your personal knowledge mapping tool, making complex information a little less daunting.",
            ],
          },
          emphasize: {
            title:
              'Master Task Management with a Personalised Intelligence Dashboard',
            descriptions: [
              "Firesight's Dashboard region, your command centre becomes a dynamic intelligence hub that adapts to your unique needs and work style. The Dashboard serves as your central access point to all of Firesight's offerings, intuitively adjusting to display the most relevant tools, insights, and data. Need to swiftly switch between real-time media surveillance, AI-generated research, and autonomous task management? The Dashboard makes it possible. It streamlines your workflow, boosts your efficiency, and facilitates seamless integration between different information regions.",
              "Moreover, the Dashboard leverages Firesight's advanced news briefing technology. It consolidates the ever-evolving digital media landscape into a succinct, personalised news briefing. Stay informed with the latest trends impacting your client industries, monitor market developments, and identify opportunities as they arise.",
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_translators: {
          name: 'Translators',
          icon: FLTranslatorsIcon,
          section1: {
            title:
              'Transform Your Translating <br/> with AI-Powered Linguistic Intelligence ',
            description:
              "As a translator, you're tasked with the vital role of building bridges between languages, cultures, and ideas. In this intricate and challenging process, Firesight's Work region becomes your ally, assisting you in managing and executing translation tasks with unprecedented efficiency. ‘Work’ is an autonomous work region, populated by  Artificial General Intelligence (AGI) powered AI agents. These agents tirelessly take care of simple-to-complex tasks, such as document analysis, sending emails, translations and contextual understanding of texts. With Work, you can automate the laborious process of initial translation and proofreading, as our AI agents can produce precise, contextually correct translations across multiple languages. Moreover, as you review and adjust translations, Work learns from your inputs. The system becomes more refined and attuned to your unique style and approach, growing into an invaluable partner that appreciates the nuances of your work",
          },
          integrations: {
            banner: FLTranslatorsBanner,
            title:
              'track Linguistic Trends and Make Informed Decisions with Media Intelligence',
            descriptions: [
              "Staying relevant in the language translation industry requires keeping pace with rapid shifts in global linguistic landscape. Firesight's Pulse, an advanced media intelligence region, keeps you at the forefront of this evolution. Pulse is constantly analysing the world’s supply of news, information and data from diverse sources across the globe. Tracking conversations, stories, articles, specific publishers across a global media spectrum. As a translator, the authentic representation of contemporary language is key. Pulse's real-time language trend insights help you craft translations that feel natural and relevant, improving the quality of your work. Pulse doesn’t stop there. It delves into the cultural nuances tied to these trends, offering a deeper understanding of language evolution. This insight allows you to deliver translations that connect at a deeper level, making your work more impactful.",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title:
              'Chat & Graph: Your Guides through the information Labyrinth',
            descriptions: [
              'As a translator, understanding the context and depth of a topic is as crucial as mastering the languages involved. The Chat and Graph regions of Firesight aid you in delving into subjects and efficiently retrieving what you seek. Chat, powered by advanced GPT-4 technology, is an intelligent AI Chat, serving as a personal assistant and analyst. Discuss topics, get explanations, and gain deeper insights into content right within your chat interface. Chat’s AI can fetch real-time data, historical context, and even cultural insights, thereby aiding you in understanding your text better. Graph, with an immersive and interactive knowledge graph interface and AI Chat engine, takes the complexity out of information exploration, delivering an interactive knowledge graph for you to visually grasp and control. Understanding complex linguistic structures, syntax patterns, and etymology becomes simpler with Graph. It helps you visualise relationships between words and phrases, providing you with a tangible linguistic landscape to navigate.',
            ],
          },
          emphasize: {
            title:
              'Stay Ahead of client-facing news with Our state-of-the-art briefing technology in dashboard',
            descriptions: [
              "Juggling multiple translation projects and tracking linguistic trends can be overwhelming. This is where the Firesight's Dashboard becomes an essential tool in your arsenal. Your personalised intelligence command centre, the Dashboard offers you an intuitive interface that dynamically adjusts to your needs. It feeds you with relevant insights, keeps track of your tasks in Work, notifies you of global linguistic trends from Pulse, and helps you manage your information queries from Chat and Graph. The Dashboard ensures that all the intelligence you need is at your fingertips. It's more than just a feature; it's your personalised assistant.",
              'Our answer to information overload is our natural language generation (NLG) powered news briefing technology - empowering you to stay abreast of important client-facing news and tailor your briefings to a level of personalisation that is as unique as your own voice.',
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_photographers: {
          name: 'Photographers',
          icon: FLPhotographersIcon,
          section1: {
            title:
              "Capture the World Through Your Lens: <br/> Stay Ahead with Firesight's News Feed",
            description:
              "Whether you are scouting locations for your next shoot or tracking trending aesthetics, Firesight's ‘News’ region has got you covered. Our state-of-the-art AI-powered technology gathers, digests, processes and presents global news and information from tens of thousands of publishing sources, in your very own personalised feed. With advanced natural language technology (NLP, NLU, NLG), the ‘News’ region summarises and contextualises the influx of information to help you keep up with the latest trends, styles, and hotspots in the photography world. Advanced regional filters will be a big bonus for many photographers. Whether it's the newest photo-editing tools or the latest award-winning shots, you'll be the first to know. Stay updated with News.",
          },
          integrations: {
            banner: FLPhotographersBanner,
            title:
              'Transforming the World of Photography with autonomous work',
            descriptions: [
              "Being a professional photographer involves juggling a myriad of tasks beyond capturing and editing pictures. 'Work', our autonomous work region, is designed to revolutionise the way you manage your diverse workload. It's not just about automating tasks; it's about harnessing the power of artifical intelligence and large language models (LLMs) to redefine your work experience. In the 'Work' region, you can delegate time-consuming tasks like image sorting sorting and categorisation, auto-tagging even portfolio narration to our autonomous AI agents, powered by advanced artificial intelligence (AI).",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title: 'Chat & Graph:\nyour new ai sidekick',
            descriptions: [
              "'Chat' and 'Graph', our innovative AI chat region and interactive knowledge graph, are engineered to provide an immersive learning experience tailored to your unique needs. ",
              'The Chat region serves as your personal mentor or sidekick. This intelligent AI chat, powered by advanced GPT-4 technology, large language models (LLMs) and a plethora of plugins and integrations feeding it new information. The result: amazing answers to your queries, deciphered client briefs, distilled key themes and tricks, even a creative inspiration for your next project.',
              'Navigating complex photography techniques, latest trends, or even historical context can be daunting. This is where ‘Graph’ region steps in. With its intuitive and visually interactive design, Graph allows you to explore and control information layouts. From learning new photography techniques to exploring the intricacies of post-processing, Graph offers a seamless and engaging method of navigating complex information sets.',
            ],
          },
          emphasize: {
            title:
              'Stay Ahead of the Curve with Media Surveillance and AI Journalism',
            descriptions: [
              "Photography is a dynamic art form, with ever-evolving trends and techniques. Firesight's 'Pulse' region harnesses the power of AI to provide comprehensive media surveillance. Track emerging photography styles, sentiment analysis on editing techniques, or the latest gear reviews to stay informed and innovative. Our neural network powered technology performs in-depth narrative tracking, providing you with insights on shifting photography landscapes worldwide. With AI-journalism, gain unbiased perspectives on unfolding photography events, granting you a deeper understanding of your craft's global narrative. ",
              'Firesight empowers you to focus on what truly matters - your art. Embrace the blend of creativity and technology and start your journey with us and redefine your photography experience.',
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_dataanalysts: {
          name: 'Data Analysts',
          icon: FLDAnalystsIcon,
          section1: {
            title:
              'Augment Your Analysis Capabilities                                 with AI and Neural Networks',
            description:
              "As a data analyst, your job involves delving into complex data sets, hunting for trends, correlations, and anomalies. With Firesight's neural networks and large language model (LLMs) capabilities, you can elevate your analysis to the next level. Our 'Work' region, home to an army of autonomous AI agents, can tackle data analysis tasks swiftly and efficiently. Using advanced natural language understanding (NLU), processing (NLP) and generation (NLG) our AI can extract, summarise and present complex data in a clear, understandable format. In the 'Chat' region, engage in a conversation with our AI to understand data trends or generate reports using your own industry vernacular. The application of Generative AI can provide invaluable insights, helping you to discern patterns and make informed decisions. Turn briefs into actionable data insights, master your client jobs, and elevate your data analysis career",
          },
          integrations: {
            banner: FLDAnalystsBanner,
            title:
              'Stay Ahead with Real-Time Media Surveillance and AI-Journalism',
            descriptions: [
              "In a profession as dynamic as data analysis, staying abreast of the latest methodologies, tools, and trends is crucial. That's where Firesight's 'Pulse' region, a media intelligence, and surveillance hub, comes in. Pulse leverages the power of media listneing and media surveillance to offer a comprehensive and constantly updated view of the global data analysis landscape. Narrative tracking keeps you informed about trending data-related topics, sentiment analysis provides insight into the public's perception of data practices, and detailed media surveillance ensures you never miss a critical update in your field. 'Pulse' utilises advanced natural language processing (NLP) technology to tailor content to your specific interests and needs. Whether it's the latest algorithms, machine learning techniques, or data visualisation tools, 'Pulse' makes sure you are always up-to-date and competitive in your field.",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title: 'Untangle Complex Data with an Interactive Knowledge Graph',
            descriptions: [
              "Data, by nature, is often complex and difficult to decipher. Firesight's 'Graph', an interactive knowledge graph, makes data comprehension seamless and intuitive through its unique visualisation capabilities. With Graph, you can interact with data sets, metrics, and analytics on a visually intuitive platform, bringing patterns and correlations to the forefront. Through advanced natural language generation (NLG), Graph provides smart prompts that guide your exploration, helping to streamline interactions and ensure you derive meaningful insights from your data.",
              "Our SaaS research platform, is like having a highly sophisticated, visual 'map' to guide you through the complex terrain of big data. 'Graph' is more than just a tool—it's a game-changer for data analysts.",
            ],
          },
          emphasize: {
            title:
              'Streamline Your Workflow with Personalised Intelligence Dashboard',
            descriptions: [
              "As a data analyst, you juggle multiple tasks simultaneously – from managing varied datasets and diverse projects to keeping track of deadlines and follow-ups. In the complex and ever-evolving landscape of data analysis, staying organised and maintaining a streamlined workflow is crucial. This is where the'Dashboard' region, a personalised intelligence command center, comes to your rescue. With our advanced news briefing technology, stay updated on project-related news, industry annoucements, and crucial data updates. Our dashboard leverages advanced NLU and NLG technologies to concisely summarise personalised updates in an easily digestible format, allowing you to stay informed without the need to sift through piles of news and information.",
              "The 'Dashboard’ adjusts to your unique needs and work patterns, serving you the most relevant tools and information as and when you need them. It also integrates with all other regions of Firesight, providing you with a single, unified space to manage your work.",
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_marketingspecialists: {
          name: 'Marketing Specialists',
          icon: FLMSpecialistsIcon,
          section1: {
            title: 'Unleash the Power:\nAutonomous Work Part 1',
            description:
              "Say hello to your future marketing team that never sleeps or tires and is infinitely scalable - meet 'Work', your region of autonomous AI agents within the Firesight platform. As a marketing specialist, imagine the immense possibilities this AI workforce opens up for you. Run email campaigns, produce go-to-market strategies or undertake exhaustive market research. Strenuous, time-consuming tasks, become a breeze with Work's artificial general intelligence (AGI) capabilities. Imagine a diligent workforce that not only sifts through copious amounts of information, news, and data but does so with a keen awareness of cultural, societal, and community nuances in various regions and demographics. Yes, Work's Artificial General Intelligence (AGI) capabilities go beyond the typical AI; they embody a deep understanding of the world. The AI agents in Work analyse information, news & data with exceptional depth, taking into account the intricate layers of regional and demographic subtleties. Consequently, the hidden patterns they uncover and the insights they serve are not just actionable but strikingly accurate and relevant. ",
          },
          integrations: {
            banner: FLMSpecialistsBanner,
            title: 'Unleash the Power:\nAutonomous Work Part 2 ',
            descriptions: [
              'Understanding market language, consumer sentiment, cultural trends and campaign performance is crucial in marketing. Our AI toolkit uses advanced Natural Language Processing (NLP) and Understanding (NLU) to analyse social media trends, forum conversations, customer reviews, and campaign data, offering unique insights that can guide your marketing strategy. Additionally, within the ‘Work’ region of the platform, you can automate repetitive tasks such as report generation and campaign tracking, allowing you to focus on strategic decision-making.',
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title: 'Real-Time bias-free narratives Propel Your Marketing game',
            descriptions: [
              "Firesight's 'News' and 'Pulse' regions present the perfect blend to keep you a step ahead. With 'News', kick-start your day with insights drawn from tens of thousands of global sources, presented in a clear, context-rich format. Our proprietary neural networks and natural language technologies (NLP, NLU, NLG) curate, summarise, and deliver these insights, allowing you to swiftly grasp global events, stories, trends & narratives essential to your marketing goals.",
              "Transition to 'Pulse', your personalised media intelligence hub. Pulse keeps track of real-time trends with state-of-the-art data visualisations. LLMs, built upon neural networks and natural language technologies, synthesise, contextualise and deliver a personalised media surveillance dashboard. Pulse goes beyond surface-level analysis by employing state-of-the-art media listening technology that let you transcend keyword & phrase tracking, decipher behavioural patterns from articles and social media posts with narrative tracking.",
              "Advanced sentiment analysis and geo-analysis tools allow you to dissect audience reactions to your campaigns, providing critical, location-specific feedback to refine and optimise your marketing strategy. Moreover, 'Pulse' also acts as a strategic foresight tool, tracking trending narratives, buzzwords and stories across the globe. This empowers you to anticipate shifts in consumer behaviour and emerging trends.",
            ],
          },
          emphasize: {
            title: 'AI-Powered Innovation Spaces:\nChat & Graph',
            descriptions: [
              "Tap into the heart of market trends with Firesight's innovative 'Chat' and 'Graph' regions. 'Chat' acts as your intuitive discovery portal, propelled by generative AI technology that is deeply attuned to cultural, societal, and community patterns. How? We combine the power of Open AIs GPT-4 with a multitude of integrations that feed in real-time news, information and sentiment from a diverse global voice. This empowers you to deep dive into uncharted realms, shining a light on hidden insights and weaving them into actionable marketing tactics. The outcome? Strategies that pulsate with the dynamic rhythm of your market.",
              "'Graph' serves as your strategic command center, harnessing the strength of neural networks. It takes intricate customer behaviors, channels, and campaign outcomes, rendering them into interactive, easy-to-understand visualisations. Navigate the rich tapestry of your marketing operations with the ease of an eagle-eyed strategist. But the strength of 'Graph' doesn't stop at retrospection. Its future-facing, predictive intelligence, trained on both current and historical patterns, offers you the invaluable gift of foresight, arming you for marketing victories on the horizon.",
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_accountants: {
          name: 'Accountants',
          icon: FLAccountantsIcon,
          section1: {
            title:
              'Harness the Power of AI: <br/>accounting Was Never This easy',
            description:
              "Navigating through the intricate world of accounting can be challenging. Firesight’s Chat and Work revolutionise this by marrying advanced AI chat capabilities with autonomous work execution, taking care of complex tasks and data analysis, and freeing up your time for strategic planning. Chat serves as your AI-powered personal assistant, providing interactive assistance that understands accounting intricacies, providing detailed reports and insightful studies in real-time. Thanks to natural language understanding (NLU), processing (NLP), and generation (NLG) techniques, Chat grasps the context of your requests and offers relevant, tailor-made solutions. Work acts as the next step, executing tasks outlined in Chat. This autonomous work region comprises of AI agents that can handle a wide array of jobs, from processing financial data to preparing intricate tax reports. As Work's AI agents learn from each task, their efficiency evolves, making them an adaptable, tireless workforce. Experience the future of accounting with Firesight's Chat and Work, a perfect blend of interactive assistance and autonomous work execution.",
          },
          integrations: {
            banner: FLAccountantsBanner,
            title:
              'gauge trends before they’re trending with our media intelligence tools',
            descriptions: [
              "As an accountant, understanding client needs, industry trends, and regulatory changes is critical. Our AI and natural language technology (NLP, NLU, NLG) powered SaaS platform, offers superior research capabilities and intelligent industry analysis. It delivers a personalised, distilled feed of the latest financial trends, industry news, regulatory changes and market dynamics relevant to you or your clients' needs. Moreover, our AI platform's advanced tools can help you analyse client objectives and provide strategic recommendations based on current legal trends and data-driven insights. Turn information into actionable accountancy strategies, and elevate your accounting career today.",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title: 'stay in tune with what matters to you',
            descriptions: [
              "Being an accountant requires being up to date with the latest financial news, trends, and regulations. This task becomes a breeze with Firesight's 'News' – your very own intelligent News Feed region. Built around sophisticated technologies such as Neural Networks, Natural Language Processing (NLP), and Natural Language Understanding (NLU), 'News' is designed to explore content that matters not only to your profession, but specifically you. This intuitive platform digs through global financial news articles, headlines, and narratives, providing you with insights tailored to your exacting preferences.",
              "Beyond news aggregation, 'News' serves as your comprehensive intelligence engine, offering deep insights and understanding of the ever-evolving financial landscape, summarising articles, making the flood of information easily digestible and always relevant. Sentiment analysis, bias-free narrative tracking, and AI-journalism offer agenda-free perspectives on unfolding financial events, presenting you with a macroscopic view of narratives from the financial world. Furthermore, our Generative AI technology crafts AI-generated articles that offer rich insights within an ad-free environment. This ensures a focused, distraction-free exploration of financial events that could impact your profession.",
            ],
          },
          emphasize: {
            title: 'Transform Your Workflow with a Dynamic Dashboard',
            descriptions: [
              "As an accountant, you juggle multiple tasks, clients, and deadlines. Firesight's 'Dashboard', your personalised intelligence command centre, is here to streamline your workflow. This dynamic platform learns and evolves with you, providing the most relevant tools and financial information intuitively. Our news briefing technology turns the vast financial landscape into easily digestible, personalised news briefings. Stay updated with financial trends, client-centric news, and important deadlines, all in one unified space. ",
              "Firesight's 'Dashboard' revolutionises the way you consume financial news. Our advanced news briefing technology harnesses the power of AI and Natural Language Processing (NLP), translating the complex financial media landscape into easily digestible, personalised news briefings. Stay updated with important financial trends, changes in legislation tax or business law and client-centric news that that are pertinent to your accounting practice and client relationships. Firesight's 'Dashboard' is the accountant's ultimate ally. Its dynamic, user-friendly interface combined with sophisticated AI technologies streamlines your practice, enhances your financial acumen, and paves the way for professional growth.",
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_journalists: {
          name: 'Journalists',
          icon: FLJournalistsIcon,
          section1: {
            title:
              'Newsroom Revolution: <br/>Unleash the Power of Autonomous AI Agents',
            description:
              "Artificial Intelligence will usher in a new era of news gathering, providing journalists with an intelligent resource. Using Natural Language Processing (NLP) and Understanding (NLU), our technology sifts through vast amounts of information, then applies complex reasoning and analysis processes to this information, pulling out the vital insights you prompted the technology to deliver! Our state-of-the-art Natural Language Technology (NLG), or Generative AI can draft preliminary content quickly, assisting you in creating an article skeleton on which you can build on. Or create engaging headlines, write compelling leads, and generate article outlines quickly, giving you a head-start in your writing process. Whether you're automating the deciphering of complex reports, uncovering underreported stories, or producing meaningful content with the tap of a button Cognistant gives you the edge you need to stay ahead of the pack.",
          },
          integrations: {
            banner: FLJournalistsBanner,
            title: 'Navigate the Knowledge Universe with a fresh perspective',
            descriptions: [
              "‘Chat’ and ‘Graph’ regions of the platform are transforming the way professionals interact with information, offering you an enriched, user-friendly experience with an intuitive AI chat system and visually intuitive knowledge maps. The ‘Chat’ region is an ingenious AI chat interface powered by the ground-breaking GPT-4 technology and a multitude of real time information integrations, that acts as your personal assistant, creative director, researcher, analyst, and scribe, all rolled into one. Chat is more than just a chat platform, it utilises advanced 'chain of thought' and 'tree of thought' techniques to better understand context, anticipate your needs, and deliver precision outcomes.",
              'Now, picture ‘Graph’, an interactive knowledge graph interface, with the same AI Chat engine under the hood as Chat. Intelligently map information in an organised and digestible format, allowing for an enhanced understanding of complex topics or stories. Imagine successful brainstorming sessions driven by smart query prompts in an infitiely expansive knowledge map, where you can freely customise the layout. Graph simply make it easier to explore, understand, and manage intricate subjects.',
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title: 'Master the Art of Research: Part 1',
            descriptions: [
              "In today's rapidly evolving media landscape, journalists need tools that not only keep them up to date but allow them to dig deeper and extract valuable insights from a sea of information. Enter the combined prowess of Firesight's News and Pulse regions – your ultimate toolkit for comprehensive media exploration, surveillance and analysis. The News region acts as your intelligent news feed, a curator of a global array of articles, publishers, topics, brekaing stories and narratives, precisely engineered for your needs. Utilising large language model (LLMs) and natural language technologies, News surpasses the clutter, offering you the essence of world events by expertly digesting, summarising, and presenting key topics. AI-generated articles provide unbiased perspectives on unfolding events, and stories, collating reporting from a global supply of reputable sources. All within an ad-free environment. Sources matter, in News a citation feature is always only one tap away. Your very own innovative news intelligence engine ready to optimise your understanding of global narratives is here.",
            ],
          },
          emphasize: {
            title: 'Master the Art of Research: Part 2',
            descriptions: [
              "Taking a step further into the Pulse region, you're equipped with a state-of-the-art media intelligence and surveillance platform. Interested in knowing which news topics are trending in a specific geographic area? Pulse tracks the popularity of news topics across the globe. Real-time buzz, trend, and hype monitoring provide a comprehensive picture of the media landscape, all supported by advanced metrics and data visualisations. Or what about being able to pinpoint the most loved or loathed topic in any country in real time... you can do that too, with our sentiment analysis intelligence. Pulse offers a focused environment for real-time awareness and accelerated learning, enabling you to stay ahead of the curve. As a journalist, the integration of News and Pulse empowers you to go beyond simple information gathering. These regions facilitate a deeper comprehension of current events and global trends. They equip you to write fact-driven, impactful stories, grounded in a thorough understanding of global narratives, trends, and sentiments. ",
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_traders: {
          name: 'Traders',
          icon: FLTradersIcon,
          section1: {
            title: 'Supercharge Your Trading with a new secret weapon',
            description:
              'Staying on top of market trends, financial news, and economic indicators is a full-time job - but what if you had an AI assistant to do the heavy lifting? Our cutting-edge technology, driven by Large Language Models (LLMs) and Neural Networks, automates data analysis and trend identification to provide you with crucial insights, thus enhancing your trading strategy. Leverage the power of AI and make more informed and less emotionally driven trades.',
          },
          integrations: {
            banner: FLTradersBanner,
            title: 'Smarter Trading\nwith Predictive Analysis',
            descriptions: [
              "Imagine having the ability to anticipate market trends, foresee risks, and discover investment opportunities before others? That's precisely what our AI-powered SaaS platform offers. Through Natural Language Technology, specifically, NLU & NLP, our AI system can analyse vast volumes of quantitative data (financial market, currency markets, sports betting markets etc.) and qualitative data (market news, industry news, sports news etc.) identifying complex patterns and trends through its full-spectrum analysis, that may elude expert human eyes. Furthermore, Firesight can be configured to have real-time market feeds and trading algorithms configured, creating a turnkey platform for evaluating real-time market trading value. Through Natural Language Generation (NLG) technology, this complex analysis, and even trade formulation process, can be condensed into actionable reports, delivered in a market conscious, and time-sensitive manner.",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title: 'The Future of Trading:\nReal-time media reconaissance',
            descriptions: [
              "Whether you're a financial trader, currency trader or a sports betting trader, this fast-paced world requires you to stay updated with news, economic indicators, market changes, changing regulatory conditions, player injuries, media hype, and so much more. However, sifting through the noise to find the gems can be time-consuming to downright impossible. Firesight is powered by AI and Artificial General Intelligence (AGI) technology that changes all that. It can expertly filter through noise on social media and digital media, surfacing relevant news, information or insights that could directly impact your trading or betting strategy. It can focus on qualitative drivers such as media hype around particular stocks or injury news for sports betting, delivering geographical breakdowns of hype origin, state-of-the-art sentiment analysis with a multitude of filters plus so much more. Researching and staying briefed on market changes has never been more streamlined or efficient. Welcome to the future of trading with our AI-powered SaaS platform",
            ],
          },
          emphasize: {
            title:
              'harness our Media surveillance <br/>technology for superior trading insights',
            descriptions: [
              "Trading, whether in stocks, currencies, commodities, or sports, is an information-intensive endeavor. The challenge lies in sifting through massive amounts of data, identifying relevant news, interpreting complex market signals, and adjusting strategies accordingly. With Firesight, this process is transformed. The News region of Firesight is your personalized news engine, employing advanced AI to filter, classify, and deliver news relevant to your trading interests. From corporate earnings announcements to key economic indicators, from player performance statistics to regulatory updates - Firesight's News region has you covered. Beyond news updates, the Pulse region is your advanced trend tracking tool. Pulse takes the filtered information from the News region, analyses the undercurrents, gauges market sentiment, and translates this into trend forecasts. It provides comprehensive, real-time market monitoring, allowing you to identify and capitalize on market opportunities faster and with more accuracy. By integrating News and Pulse, Firesight offers a comprehensive, real-time information and trend analysis system. This combination not only streamlines your information gathering but also enhances your decision-making process",
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_seospecialists: {
          name: 'SEO Specialist',
          icon: FLSSpecialistsIcon,
          section1: {
            title: 'Welcome to the future of SEO',
            description:
              "With Firesight, your role as an SEO specialist evolves, transforming from merely understanding search engine algorithms and tracking keywords to mastering them. Our platform is equipped with GPT-4 integration, as well as a multitude of other integrations, including all major search engines and social media platforms, creating a seamless flow of information that is then coupled with our state-of-the-art AI technology and large language models (LLMs). The possibilities are nearly endless.  In the 'Work' region, we redefine automation. Beyond the basics of data analysis and keyword research, Work can conduct competitor analysis, site audits, backlink analysis, on-page SEO evaluation, and even content optimisation. These tasks are accomplished with remarkable efficiency, freeing up your time to strategise and innovate.",
          },
          integrations: {
            banner: FLSSpecialistsBanner,
            title: 'Generative AI powerhouse: <br/>part 1',
            descriptions: [
              "The 'Chat' region is not just an AI-powered communication hub; it is a manifestation of the transformative power of generative AI in the realm of SEO. Seamlessly blending machine intelligence and human creativity, Chat positions itself as a dynamic domain of strategic and operational SEO mastery. With its generative AI capabilities, Chat undertakes diverse SEO tasks that move beyond simple analysis and data-driven insights. The generative power of Chat extends to creating tailored, SEO-optimised content.",
              'Whether you need captivating blog posts, compelling meta descriptions, or keyword-rich articles, Chat uses AI language models to produce high-quality, engaging content that is designed to rank, captivate, and convert. Taking the interpretation of complex SEO data to new heights, Chat uses generative AI models to transmute intricate datasets into digestible and actionable insights. It produces extensive SEO reports, comprehensive competitor analysis, and detailed breakdowns of search engine algorithm updates. It distills the complex language of SEO into lucid, understandable intelligence, empowering you to make informed strategic decisions.',
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title: 'Generative AI powerhouse:\npart 2',
            descriptions: [
              "One of the most impressive capabilities of Chat's generative AI is its ability to anticipate and forecast SEO trends. By harnessing machine learning and predictive analytics, Chat analyses historical data and current market patterns to predict upcoming shifts in the SEO landscape. This not only prepares you for the future but also allows you to proactively shape your SEO strategy, always keeping you one step ahead of the competition. Interacting with Chat is akin to engaging in an enlightening dialogue. By leveraging advanced Natural Language Generation (NLG), Chat delivers meaningful conversations, discussing the latest SEO trends, answering your queries, and providing astute recommendations. Chat acts as a real-time personal SEO mentor, offering guidance and insights that are crucial for your strategic planning.",
              "In essence, the Chat revolutionises SEO management by harnessing the power of generative AI. It turns you from a passive player to an active strategist in the digital SEO landscape, ensuring your strategies not only react to changes but also influence them. It's not merely about managing SEO anymore; with Chat, you can truly master it.",
            ],
          },
          emphasize: {
            title:
              'Transform SEO Research with our state-of-art Media Intelligence Technology',
            descriptions: [
              "Elevate your SEO strategies with a AI research assistant, designed to keep pace with real-time SEO trends and industry updates. Our media intelligence technology scours the web, offering actionable insights such as emerging keywords, trending topics, and current best practices. Use this tool to conduct in-depth research for client briefs, understanding industry nuances and target market specifics. This enables a bespoke SEO strategy, improving client's online visibility and business performance. Whether it's an e-commerce site aiming to boost product visibility, a blog seeking readership growth, or a service-based website looking to increase leads, our AI is adaptable. Its advanced technology doesn't just collect data; it interprets and presents it for effective SEO strategy formulation. Then it takes one step further... it acutally executes a variety of tasks through our cutting edge AGI-powered ‘Work’ Region of Firesight. Gain a killer edge in the hyper-competitive realm of SEO and digital marketing.",
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_researchers: {
          name: 'Researchers',
          icon: FLResearchersIcon,
          section1: {
            title:
              'Empowering Research with AI: <br/>Revolutionise Your Workflow',
            description:
              "As researcher, you understand the value of data-driven insights, but the sheer volume of data to be analysed can be daunting. That's where AI, and in particular Artificial General Intelligence (AGI), comes into play. With AI, you can sift through colossal volumes of academic literature in a fraction of the time it would take manually. But AGI takes it a step further: it can formulate hypotheses, design and execute experiments, interpret complex results; and even draft & disseminate your research papers. Harnessing the power of neural networks and natural language technology (NLP, NLU, and NLG), these intelligent systems are capable of processing, understanding, and generating human-like text. Large Language Models (LLMs) can understand the context and semantics of your research field, helping you draft more accurate and efficient content. Let our SaaS platform harness the potential of AI and AGI to revolutionise your research processes.",
          },
          integrations: {
            banner: FLResearchersBanner,
            title:
              'Unleash the Potential of AI: Streamlined Research and Enhanced Productivity',
            descriptions: [
              "Our platform's ability to manage vast amounts of data is only the tip of the iceberg. Neural networks, the underlying technology of our state-of-art AI, mimic the human brain's own method of finding patterns and making connections. They can identify trends in your research data that could easily be overlooked. Furthermore, the natural language technology incorporated in our platform goes beyond merely understanding language (NLU) and generating human-like text (NLG), also called generative AI. The cutting-edge NLP capabilities make it possible to extract meaning from your research data, enhancing the quality of your insights and ensuring your work's relevance and impact. With these tools at your disposal, you can focus on the critical aspects of your research, enhancing productivity and facilitating groundbreaking discoveries.",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title:
              'Stay Ahead in Your Field with Media Surveillance technology ',
            descriptions: [
              "With Firesight's 'Pulse' region, our advanced artificial intelligence (AI) platform constantly scans, analyses and summarises the vast global landscape of news, information & research publications. Utilising neural networks and large language models (LLMs), it tracks trending research topics, academic discussions, news relevant to the industries & verticals of your clients, The media surveillance feature monitors the popularity of topics across the globe, giving you a pulse on what the world's research community is focusing on. Our sentiment analysis technology goes a step further, evaluating the emotional tone and public opinion within these narratives. This offers a unique insight into the reception and impact of various research themes and findings.",
              "What's more, our artificial general intelligence (AGI) technology takes a deep dive into the ocean of academic literature - digesting, interpreting and presenting key findings from a multitude of research papers, information sources and news articles. In essence, 'Pulse' keeps you ahead of the curve, providing the tools for real-time awareness and accelerated learning. The transformative power of our media intelligence tools can help to ensure you remain at the forefront of your field.",
            ],
          },
          emphasize: {
            title: 'Interactive Knowledge Discovery with Chat and Graph',
            descriptions: [
              "In your research journey, having an intuitive, easy-to-use interface for information interaction is vital. Enter 'Chat' and 'Graph', two regions of the Firesight platform designed to enhance your research process.'Chat' is your personal research companion, a powerful AI chat interface that employs advanced GPT-4 technology with manyreal time information source integrations. This AI Chat serves as your personal assistant, research team, data analyst, and scribe; all rolled into one. Whether you need to understand complex research data, brainstorm ideas for a new project, or even write up a comprehensive report, Chat is ready to assist. Then there's 'Graph', an interactive knowledge graph that redefines the way you interact with and control information. Graph lets you visually navigate the wealth of research information available, arranging it in a way that makes sense to you. It’s your organised, intuitive, and interactive approach to information exploration. These two powerful tools work in harmony, using large language models (LLMs), natural language understanding (NLU), and natural language generation (NLG) to provide an immersive, intuitive research experience. ",
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_socialmediamanager: {
          name: 'Social media manager',
          icon: FLSMManagerIcon,
          section1: {
            title: 'Streamline Your Workflow with an AI sidekick',
            description:
              'As a social media manager, your hands are undoubtedly full with content creation, audience engagement, performance tracking, and so much more. AI can be your greatest ally in streamlining these tasks. Leveraging neural networks and natural language technology (NLP, NLU, NLG), our SaaS platform automates routine activities and provides insightful analytics, freeing up time for you to strategise and create compelling content. Transform your social media management with AI today.',
          },
          integrations: {
            banner: FLSMManagerBanner,
            title:
              'Harness AI for Deeper Audience Insights and Tailored Engagement',
            descriptions: [
              "Navigating audience sentiments and engagement levels is critical to executing a successful social media campaign. With the AI capabilities of our SaaS platform, you gain more than just basic analytics. Our state-of-the-art media listening technology, powered by advanced neural networks and Large Language Models (LLMs), digs deeper into your audience interactions. It transcends mere keyword & phrase tracking, decipher behavioural patterns from articles and social media posts with narrative tracking. AI-powered sentiment analysis enables you not just to see what content receives the most engagement, but also to comprehend the feelings your audience harbours towards your brand. This holistic understanding provides you with the ability to respond promptly to shifts in audience sentiment. Powerful research and media intelligence capabilities are offered through our 'Pulse' and 'News' regions provide up to the minute insights on any topic, story or publisher and keeps you updated on global trends.",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title: 'Welcome to an autonomous future delivering SMMS bliss',
            descriptions: [
              "The dynamic landscape of social media calls for an intelligent approach that consistently evolves with your audience. At the core of Firesight is 'Chat' our AI chat powered by GPT-4, as well integrations with dozens of real-time information sources, feeding ‘Chat’ the worlds thoughts, opinions, feelings and emotions. The output is generative AI bliss that understands global cultures, societies and communities on a macro and micro level. ",
              "Connect with the intuitive AI Chat for creative brainstorming, in-depth market research, or technical problem-solving. This ingenious system, equipped with natural language processing (NLP), doesn't just provide answers; it comprehends and anticipates your needs. Its advanced 'chain of thought' and 'tree of thought' prompting techniques ensure you receive accurate, contextually-aware responses that enhance the quality of your interactions and outcomes.",
              "But the power of Chat doesn't stop there. It's also your bridge to the 'Work' region, where autonomous AI agents stand ready to execute tasks swiftly and efficiently. Need a report generated, list of accurate hashtags produced for a post, or a data set analysed? Simply discuss your requirement with our AI Chat, and watch as the task is seamlessly taken over by the autonomous ‘Work’ AI agents, delivering results with exceptional efficiency and accuracy.",
            ],
          },
          emphasize: {
            title:
              'Redefine Your World View with Unparalleled Information insight & control',
            descriptions: [
              "In today's fast-paced, data-driven world, staying informed is not just a luxury but a necessity, but is not always easy with torrents of click bait and misinformation. The News region, a highly intelligent News Feed curates an information environment tailored just for you. It's an expanse where tens of thousands of sources are meticulously analysed to offer a comprehensive understanding of global trends, stories, current events, and hidden patterns beneath the mainstream media surface. Firesight's News region employs advanced AI algorithms and Neural Networks to digest, summarise, and contextualise articles and topics, resulting in an effortless, bias-free, and ad-free exploration of world events. AI-generated articles provide fresh, agenda-free perspectives on unfolding events and breaking stories. The News region goes far beyond traditional news aggregators, delivering a deeper understanding of the narratives shaping our world and cultures.",
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_lawyers: {
          name: 'Lawyers',
          icon: FLREAgentsIcon,
          section1: {
            title: 'Revolutionising Legal Research and Case Analysis',
            description:
              "In the world of law, thorough research and data analysis can make the difference between victory and defeat. Enter Firesight's ‘Work’ region, your advanced AI assistant ready to revolutionise your legal research process. Work is an autonomous work region where AI agents take on a variety of tasks. From poring through case law and legal statutes, summarising complex legal documents to identifying critical court precedents, Work is designed to manage the immense workload characteristic of the legal profession. Its advanced AGI technology and neural networks can efficiently navigate through vast legal databases, presenting you with precise, actionable intelligence. The Work region isn't just about completing work — it's about optimising processes and increasing productivity. Let the future of legal research be powered by Work, where cutting-edge artificial intelligence, automation, and versatility redefine productivity in the legal field.",
          },
          integrations: {
            banner: FLREAgentsBanner,
            title: 'cut through the noise and Expand Your information Horizon',
            descriptions: [
              "As a legal professional, you understand the importance of staying updated with legal trends, new laws, and notable case judgements. Firesight's News region offers a comprehensive and personalised news experience, meticulously engineered for you and you alone. This highly intelligent, personalised, News Feed region utilises truly state-of-the-art neural networks to absorb and process, information, data, legal news articles, court judgements, policy updates and so much more, from tens of thousands of information and publishing sources globally. Advanced algorithms ensure that every piece of information is contextualised and summarised, delivering you a deeper comprehension of global legal narratives. The News region also leverages AI-journalism, producing bias-free, ad-free content that enhances your understanding of your specific interests. Dive into the future of smart news with Firesight.",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title: 'Interactive Knowledge Discovery and Intelligent Assistance',
            descriptions: [
              "Within the complexities of law, having an intelligent assistant and interactive knowledge graph could be a game-changer. The seamless combination of Firesight's Chat and Graph regions empower legal professionals in ways never imagined before. The Chat region, powered by breakthrough GPT-4 technology and sophisticated natural language understanding (NLU) capabilities, serves as your personal legal assistant. Whether it's understanding the intricacies of case law, carrying out exhaustive legal research, or generating comprehensive legal briefs, Chat's AI Chat delivers with precision and speed. It plugs into an extensive network of legal databases and third-party tools, ensuring you are always ahead of the curve. Not to mention it can plug into your own private legal database through our knowledgebase feature.",
              'Complementing Chat is the Graph region, an interactive knowledge graph, driven by the same AI engine powering ‘Chat’. Graph makes the process of interacting with information intuitive, organised, and overwhelm-free. Graph visually represents complex legal information in a highly accessible manner. Within the interface, legal professionals can arrange and interact with vast repositories of legal data. Information is presented as interconnected nodes, allowing users to understand the correlations and patterns that can be the key to successful legal outcomes. Experience an entirely new way to navigate legal complexity with Graph.',
            ],
          },
          emphasize: {
            title: 'The Lawyer’s Command Centre is dashboard',
            descriptions: [
              "The legal profession demands rigorous information management. Firesight's Dashboard offers a tailored solution, presenting an overview of your legal work, research, and media intelligence in one unified space. With the Dashboard, you get a personalised command centre that intuitively adjusts to your work patterns and needs. It learns and evolves with you, offering the most relevant tools, insights, and data at your fingertips. From tracking cases and updating legal research to monitoring legal news, the Dashboard manages it all seamlessly. The Dashboard goes a step further by leveraging Firesight’s advanced news briefing technology, turning the vast digital legal landscape into personalised, easily digestible news briefings. Streamline your legal workflow, boost efficiency, and take control of your daily tasks with Firesight's Dashboard.",
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title: "embrace The <span style='color: #E93249'>rise of AI</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising.',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_prcomspecialists: {
          name: 'PR & Communications specialists',
          icon: PRCSpecialistsIcon,
          section1: {
            title: 'media intelligence reimagined: part 1',
            descriptions: [
              "In the fast-paced world of PR & Communications, staying attuned to the ebb and flow of media narratives is crucial. Firesight's Pulse Region is a powerful media intelligence and surveillance dashboard, designed to keep professionals like you at the heart of global trends. Pulse leverages advanced technologies like natural language understanding (NLU), sentiment analysis, and narrative tracking to provide you with a comprehensive, real-time picture of the global media landscape. Our technology scans tens of thousands of sources, absorbing and processing global news articles, stories and headlines. Identifying key patterns and trending topics across specific countries, cities or geographical regions, giving you unparalleled insight into territorial narratives. Imagine absorbing 95% of news content in just 5% of the time. Firesight filters out the noise to deliver concise, fact-driven news summaries, enabling you to grasp the essence of information quickly. This advantage, coupled with an ability to reflect on overarching trends and sentiments in media narratives over an entire year, or within the last few minutes, will revolutionise your understanding of the global media realm.",
            ],
          },
          integrations: {
            banner: PRCSpecialistsBanner,
            title: 'media intelligence reimagined:\npart 2',
            descriptions: [
              "This intuitive platform offers a unique blend of guided and self-guided media intelligence. You can choose to delve into topics that our AI brain identifies as important to you or freely explore your areas of interest with your expert eye. With media surveillance tools, you can track specific keywords, phrases, and even narratives. Paired with a sophisticated alert system, this allows you to stay ahead of the curve and anticipate shifts in the media landscape, vital for strategic planning and reactive communications.\nThe Pulse's real-time buzz, trend, and hype monitoring, supported by insightful metrics and state-of-the-art visualisations, offers a focused environment for real-time awareness and accelerated learning. Harnessing these features, you can craft communication strategies that resonate with your audiences, ensuring your message doesn't just reach them—it influences them.\n Experience a deeper comprehension of current events and global trends with Pulse, the heartbeat of the global media landscape. Amplify your message, influence your audience, and redefine your success in PR & Communications with Firesight.",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title: 'ad-free. bias-free. just news',
            descriptions: [
              'Stay ahead of the curve and anticipate industry changes with Firesight’s News region. This highly intelligent News Feed harnesses large language models (LLMs), advanced AI algorithms, and neural networks to offer a curated and highly personalised media environment. Gain a macroscopic view of unfolding stories, events, articles from every corner of the publishing world, or a microscopic view into a precise event, brand, even narratives or agendas embedded into media coverage. The ‘News’ region will keep you informed on surface level coverage and far below.',
              "Firesight's news briefing technology, paired with AI-journalism, offers you a profound understanding of global trends and current events. Through contextualised articles and bias-free, AI-generated articles, you have access to rich insights that cut through the noise of the news.",
            ],
          },
          emphasize: {
            title: 'Redefine what’s possible with Firesight',
            descriptions: [
              "Experience the powerful synergy between Chat and Work, two key regions in the Firesight platform designed to take PR & Communications to the next level. Utilise state-of-the-art artificial general intelligence (AGI) and natural language generation (NLG) technologies to streamline your workflow, from the conception of an idea in a chat to its execution by autonomous AI agents. The Chat region is a smart AI chat interface that serves as your personal assistant, analyst, and consultant. Powered by the revolutionary GPT-4 technology and dozens of real-time information sources like search and social media platforms. Chat's AI chat can translate your queries into tasks for Work's AI agents to execute in real-time. In a world where responsiveness is critical, this ability to convert discussion into immediate action can be a game-changer.",

              "Whether you're tracking media relations or setting up crisis alerts, Chat's AI chat uses advanced 'chain of thought' and 'tree of thought' prompting techniques to grasp the subtleties of your requests. What about maintaining a consistent brand voice across all communication mediums? Generative AI from the Chat can be trained to replicate your brand voice, generating content in line with it, ensuring brand consistency and a constantly recognisable brand narrative.",
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "Embrace the <span style='color: #E93249'>rise of ai</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising. ',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',              
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_entrepreneurs: {
          name: 'Entrepreneurs',
          icon: EntrepreneursIcon,
          section1: {
            title: 'Transform Your Vision into Reality with Firesight',
            description:
              "Entrepreneurship is about pushing boundaries and exploring uncharted territories. It's about transforming visions into realities. Firesight's Artificial General Intelligence (AGI) engine that powers the ‘Work’ region is a game-changer for the innovative entrepreneur. Imagine if you could delegate a range of complex tasks, from gathering information for a pitch deck, in-depth competitor analysis for a market, structuring data for a business plan or leveraging Generative AI, actually have the entire business plan created for you. This is what is possible through ‘Work’. This and so much more.",
          },
          integrations: {
            banner: EntrepreneursBanner,
            title: 'thrive in a competitive entrepreneurial landscape',
            descriptions: [
              "In the fast-paced world of entrepreneurship, staying ahead requires not just hard work, but smart work. And that means making informed decisions based on accurate, timely, and relevant information. With Firesight's advanced AI-journalism, you no longer have to navigate the cluttered world of news. Our News region scans tens of thousands of sources worldwide, processes vast amounts of information, and presents you with bias-free, ad-free articles produced by AI, that collate multiple informational and editorial sources to give you distilled truth. Utilising Natural Language Understanding (NLU) and Natural Language Generation (NLG), we customise the news to fit your unique demands - imagine absorbing 95% of news content in just 5% of the time, through concise fact-driven news summaries. Gain deep insights into global business trends, fast moving markets and sectors or undiscovered niches that tempt the shrewd opportunist. ",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title: 'Unleash Your Potential and embrace your ambitions',
            descriptions: [
              "Entrepreneurship is a constant conversation - with partners, investors, customers, and the market. Imagine if this conversation was elevated by the combined intelligence of millions of articles, the real-time pulse of social opinion, and the depth of AI understanding. Welcome to Chat, Firesight's smart AI chat region, where this conversation happens.",
              "Equipped with a powerful OpenAI GPT-4 chassis - driven by the engine of a multitude of integrations that feed real time information, news and societal opinion, Chat listens, understands, responds, and anticipates in ways unimaginable before. It's more than an AI chat tool. It's a co-strategist that helps you navigate market trends, a researcher that unearths insights from a vast expanse of data, and a reliable assistant that's always informed and ready to assist.",
              "Chat's intelligent chat engine leverages state-of-the-art natural language understanding (NLU) and natural language generation (NLG) capabilities to deliver interactive assistance that's both comprehensive and context-aware. It's designed to interpret complex commands, handle dynamic queries, and provide insightful feedback that can empower your decision-making.",
            ],
          },
          emphasize: {
            title: 'Turning Media Intelligence into Business Opportunity',
            descriptions: [
              "Every entrepreneur knows the importance of a well-crafted narrative. Your brand's story can inspire your audience, set you apart from the competition, and strengthen your market position. To tell a compelling story, you need to understand the global narratives and media trends shaping the world around you. That's where Firesight's ‘Pulse’ region comes into play. Firesight's state-of-the-art media intelligence and surveillance region, offering an in-depth analysis of the global media landscape. It's your all-in-one platform for grasping global narratives, staying briefed on unfolding and relevant stories and aligning your brand's story with your target audience's interests and concerns.",

              "With Pulse, you can follow the media's heartbeat and anticipate the next big trend. You gain an edge by leveraging our next-generation sentiment analysis technology, narrative tracking, and media surveillance tools. Pulse's geographical analysis feature lets you track and understand regional trends with incredible accuracy and detail. These sophisticated technologies process an incredible amount of data from tens of thousands of sources, around the clock, enabling you to stay ahead of the curve and make your business decisions with wisdom.",
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "Embrace the <span style='color: #E93249'>rise of ai</span>",
            descriptions: [
              'The escalating impact of AI is irrefutable. The number of jobs being displaced or affected by AI is rising. ',
              'Maintain a competitive edge in an AI-impacted work environment with Firesight and fight fire with fire.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
        fl_travelagents: {
          name: 'Travel Agents',
          icon: FLTAgentsIcon,
          section1: {
            title:
              "Unleash Your Creativity with <span style='color: #E93249'>AI-Powered</span> Writing Tools",
            description:
              'AI tools can provide advanced grammar and syntax suggestions, conduct automated research, detect plagiarism, and provide content ideas. This can greatly reduce the time spent on editing and researching, allowing writers to focus on creativity and storytelling. AGI could further enhance this by consuming news and media intelligently, providing the writer with up-to-date, relevant information for their work.',
          },
          integrations: {
            banner: FLGDesignersBanner,
            title:
              "Writing is <span style='color: #E93249'>art</span> the best artists deserve the best tools",
            descriptions: [
              "Our AI-driven writing assistant can free up your creative space by handling the heavy lifting of editing, grammar, syntax correction, and even plagiarism detection. Instead of focusing on these technical aspects, you can dedicate your energy towards creating compelling narratives. The AI can also suggest content ideas, sparking your imagination when you need it the most. What's more, it's all just a few clicks away in a simple, user-friendly interface.",
            ],
          },
          features: {
            banner: FLFeaturesBanner,
            title: 'Optimise Your Productivity with Autonomous Work Agents',
            description:
              "As a writer, your craft demands a high degree of focus and mental energy. 'Work', our pioneering autonomous work region that utilises state-of-the-art generative AI and Large Language Models (LLMs) offloads administrative tasks and lets you devote more time to what you do best - writing. From data analysis, research, scheduling, to managing social media accounts, our AGI-powered AI agents are capable of handling a wide variety of tasks, liberating you from distractions and time-consuming chores. You can also have our agents generate reports and content to provide preliminary drafts or background information for your writing assignments. Swarm is not just about automation – it is about optimising your productivity, so you can focus on delivering your best work.",
          },
          emphasize: {
            title:
              "Discover trends before they’re trending with our <span style='color: #E93249'>Research & Media intelligence</span> Tools",
            descriptions: [
              'In a rapidly evolving landscape like writing, being on top of industry trends and carrying out efficient research are two sides of the same coin. Our Artificial General Intelligence (AGI) technology addresses both these needs seamlessly. The AGI is designed to intelligently consume news and media, providing you with a personalised, distilled feed that keeps you informed about the latest writing trends, literary news, and popular themes. Instead of spending hours navigating through countless sources, you can stay updated and informed effortlessly.',

              'Streamline your research, a crucial but often time-consuming aspect of writing. Our AGI tech digs up relevant information from across the web in seconds, presenting data in an easily digestible format. This enables you to focus on weaving compelling narratives rather than sifting through piles of information. With AGI, you can even discover unique insights that others might miss, giving your work an edge. Firesight gives you more time to focus on what you love the most – crafting engaging, relevant, and insightful stories. Embrace the future of writing and stand out from the pack.',
            ],
          },
          jobAffectionByAI: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "You need to <span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'The number of jobs being replaced/affected by AI is rising - new technology is fueling business cost savings.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          activeFreelancers: {
            chart: {
              img: FLWritersChart,
            },
            title:
              "<span style='color: #E93249'>stand out</span> in a changing world",
            descriptions: [
              'The global pool of freelance talent is growing - people want more freedom.',
              'More and more freelancers are competing for the same supply of work as the demand intensifies. You need to separate yourself from the pack. Firesight is your secret weapon that makes you smarter and more efficient.',
            ],
          },
          statistics: {
            item1: {
              value: '16%',
              comment:
                'Estimated Annual Freelancer Marketplace Growth (2021-2028)',
            },
            item2: {
              value: '80%',
              comment: 'Estimated Global Workforce Freelancing by 2030',
            },
            item3: {
              value: '17M+',
              comment: 'Registered Freelancers on Upwork in 2023',
            },
          },
        },
      },
    },
    startup: null,
    agency: null,
    enterprise: null,
  },
}
