import React, { useEffect } from 'react';
import { Button } from 'antd';
import { SimpleModal } from '@components/modal';

const WaitlistModal = ({ isVisible, onClose }) => {
  console.log('Rendering WaitlistModal, isVisible:', isVisible);

  useEffect(() => {
    if (isVisible) {
      const seenWaitlist = localStorage.getItem('seen-waitlist');
      if (!seenWaitlist) {
        localStorage.setItem('seen-waitlist', 'true');
      }
    }
  }, [isVisible]);

  const modalContent = (
    <div className="text-center text-white font-lekton">
      <h2 className="text-3xl font-bold mb-6">Welcome to the Firesight Waitlist</h2>
      <p className="text-xl mb-4">
        Thank you for your interest in Firesight. We&apos;re gradually onboarding new users.
      </p>
      <p className="text-xl mb-4">
        We&apos;ll add <b>free monthly credits</b> to your account as soon as it&apos;s your turn to be granted access.
      </p>
      <p className="text-xl mb-6">
        In the meantime, allow us to welcome you to our Discord community with this <u><a href="https://discord.gg/Ty8Jdx8uvc">Discord Invite Link</a></u>
      </p>
      <Button
        type="primary"
        onClick={onClose}
        className="text-xl px-8 py-3 h-auto bg-customRed hover:bg-customRed/80"
      >
        Close
      </Button>
    </div>
  );

  return (
    <SimpleModal
      open={isVisible}
      setModal={onClose}
      modalCnt={modalContent}
      width={700}
      footer={null}
      style={{
        top: 180,
        color: "white",
      }}
    />
  );
};

export default WaitlistModal;
