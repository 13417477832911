import { useEffect, useState, useCallback, useMemo } from 'react';
import { useSessionContext } from '@supabase/auth-helpers-react';
import useUser from '@/hooks/useUser';
import AccessDeniedModal from './AccessDeniedModal';
import WaitlistModal from '@/common/components/waitlistModal/WaitlistModal';
import { debounce } from 'lodash';  // Make sure to install lodash if not already present

const GlobalAccessDeniedModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [isWaitlistUser, setIsWaitlistUser] = useState(false);
  const { session } = useSessionContext();
  const { getRolesOfUser } = useUser();

  const handleAccessDenied = useCallback(
    debounce(() => {
      console.log('Access denied event received');
      const waitlistUser = localStorage.getItem('isWaitlistUser') === 'true';
      console.log('Is waitlist user (from localStorage):', waitlistUser);
      setIsWaitlistUser(waitlistUser);
      setShowModal(true);
      localStorage.removeItem('accessDenied');
      localStorage.removeItem('isWaitlistUser');
    }, 300),
    []
  );

  useEffect(() => {
    window.addEventListener('accessDenied', handleAccessDenied);

    // Check localStorage on mount
    if (localStorage.getItem('accessDenied') === 'true') {
      handleAccessDenied();
    }

    return () => {
      window.removeEventListener('accessDenied', handleAccessDenied);
      handleAccessDenied.cancel();  // Cancel any pending debounced calls
    };
  }, [handleAccessDenied]);

  useEffect(() => {
    if (session) {
      const checkWaitlistUser = async () => {
        const userRoles = await getRolesOfUser();
        console.log("User roles:", userRoles);
        if (userRoles && userRoles.length === 1 && userRoles[0] === 'FiresightWaitlistUser') {
          if (!localStorage.getItem('seen-waitlist')) {
            setIsWaitlistUser(true);
            setShowModal(true);
          }
        }
      };
      checkWaitlistUser();
    }
  }, [session]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  console.log('Render GlobalAccessDeniedModal, showModal:', showModal, 'isWaitlistUser:', isWaitlistUser);

  const modalComponent = useMemo(() => {
    if (!showModal) return null;
    
    console.log('Rendering modal, isWaitlistUser:', isWaitlistUser);
    return isWaitlistUser ? (
      <WaitlistModal isVisible={showModal} onClose={handleCloseModal} />
    ) : (
      <AccessDeniedModal isVisible={showModal} onClose={handleCloseModal} />
    );
  }, [showModal, isWaitlistUser, handleCloseModal]);

  return modalComponent;
};

export default GlobalAccessDeniedModal;
