import LinkedInIcon from '@/assets/img/footer/footer_linkedin.svg'
import MediumIcon from '@/assets/img/footer/footer_medium.svg'
import InstagramIcon from '@/assets/img/footer/footer_instagram.svg'
import TwitterIcon from '@/assets/img/footer/footer_twitter.svg'
import DiscordIcon from '@/assets/img/footer/footer_discord.svg'

import DashboardIcon from '@/assets/img/footer/dashboard_icon.svg'
import HiveIcon from '@/assets/img/footer/hive_icon.svg'
import NewsIcon from '@/assets/img/footer/news_icon.svg'
import WorkIcon from '@/assets/img/footer/work_icon.svg'
import GraphIcon from '@/assets/img/footer/graph_icon.svg'
import PulseIcon from '@/assets/img/footer/pulse_icon.svg'

export const FooterData = {
  iconList: [LinkedInIcon, MediumIcon, InstagramIcon, TwitterIcon, DiscordIcon],
  companySummary: [
    {
      text: 'Pricing',
      link: '/static/pricing',
    },
    {
      text: 'Solutions',
      link: '/static/solutions',
    },
    {
      text: 'About Us',
      link: '/static/about',
    },
    {
      text: 'Product',
      link: '/',
    },
    {
      text: 'Desktop App',
      link: '/desktop',
    },
    {
      text: 'AI Impact Index',
      link: 'https://impact.firesight.ai/ai-impact-index/categories',
    },
    {
      text: 'Contact Us: hello@firesight.ai',
      link: 'mailto:hello@firesight.ai',
    }
  ],
  pages: [
    {
      text: 'Dashboard',
      link: '/dashboard',
      icon: DashboardIcon,
    },
    {
      text: 'Chat',
      link: '/chat',
      icon: HiveIcon,
    },
    {
      text: 'News',
      link: '/news',
      icon: NewsIcon,
    },
    {
      text: 'Work',
      link: '/work',
      icon: WorkIcon,
    },
    {
      text: 'Graph',
      link: '/graph',
      icon: GraphIcon,
    },
    {
      text: 'Pulse',
      link: '/pulse',
      icon: PulseIcon,
    },
  ],
  agencyList: [
    {
      city: 'FLORIDA',
      address:
        '7600 Dr Phillips Blvd Bay 158, Orlando, Florida, 32819, UNITED STATES',
    },
    {
      city: 'MANCHESTER',
      address:
        'Hanover Building Corporation St, Manchester, M4 4AH, UNITED KINGDOM',
    },
    {
      city: 'LONDON',
      address:
        'North West House, 119 Marylebone Rd, London, NW1 5PU,UNITED KINGDOM',
    },
    {
      city: 'DELAWARE',
      address:
        '1180 N Town Center Dr #100, Las Vegas, Nevada, 89144,UNITED STATES',
    },
    {
      city: 'CANBERRA',
      address: '1 Moore St, Canberra, ACT, 2601, AUSTRALIA',
    },
  ],
  termsList: [
    {
      text: 'Terms & Conditions',
      link: '/',
    },
    {
      text: 'Privacy Policy',
      link: '/',
    },
    {
      text: 'firesight.ai 2023. All Right Reserved.',
      link: '/',
    },
  ],
}
