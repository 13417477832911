import { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useRouter } from "next/router";
import { useSessionContext } from "@supabase/auth-helpers-react";
import clsx from "clsx";
import styles from "./GuestLayout.module.css";
import { headerData } from "@/utils";

const Navbar = dynamic(() => import("@/common/layout/navbar/Navbar"), { ssr: false });
const Footer = dynamic(() => import("@/common/layout/footer/Footer"), { ssr: false });
const CustomHeader = dynamic(() => import("@/common/layout/header/Header"), { ssr: false });

import HomeBannerLg from "@/assets/img/home_illustration_lg.png";
import HomeBannerMd from "@/assets/img/home_illustration_md.png";
import HomeBannerSm from "@/assets/img/home_illustration_sm.png";

const GuestLayout = ({ children }) => {
  const [flag, setFlag] = useState(false);
  const [staticFlag, setStaticFlag] = useState(false);
  const router = useRouter();
  const { session } = useSessionContext();

  useEffect(() => {
    setFlag(/[a-zA-Z]/.test(router.asPath));
    setStaticFlag(router.asPath.includes('static') || router.asPath === '/');
  }, [router.asPath]);

  return (
    <div className="relative">
      {!flag ? (
        <div className="lg:mb-10">
          {!session ? (
            <>
              <div
                className={clsx(
                  "md:bg-transparent bg-gradient-to-b from-slate-900/10 via-slate-900/40 to-transparent",
                  styles.navbar_container
                )}
              >
                <Navbar />
              </div>
            </>
          ) : (
            <div className="z-50 absolute w-full mt-[20px] mb-[10px]">
              <CustomHeader
                data={headerData.chat}
                isSettingPanel={true}
              />
            </div>
          )}
          <div
            className={clsx(
              "md:-mx-4 lg:-mx-8 min-[1199px]:-mx-12 min-[1280px]:-mx-14 -mt-3 md:-mt-4 lg:-mt-8 min-[1199px]:-mt-[42px] relative"
            )}
          >
            <Image
              src={HomeBannerLg}
              style={{
                backgroundSize: "auto",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              alt="home page banner"
              className="w-full hidden lg:block"
            />
            <Image
              src={HomeBannerMd}
              style={{
                backgroundSize: "auto",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              alt="home page banner"
              className="w-full hidden md:block lg:hidden"
            />
            <Image
              src={HomeBannerSm}
              style={{
                backgroundSize: "auto",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              alt="home page banner"
              className="w-full block md:hidden"
            />
            <div className="pl-4 md:pl-8 lg:pl-[90px] absolute top-[44%] md:top-[29%] lg:top-[27%]">
                  <h1 className={`max-w-[400px] lg:max-w-[757px] uppercase text-[32px] md:text-4xl lg:text-[68px]/normal font-bold ${styles.headingMark}`}>
                    Let your intelligence take flight
              </h1>
            </div>
          </div>
        </div>
      ) : !session ? (
        <div className="-mt-3 md:-mt-4 lg:-mt-8 min-[1199px]:-mt-[42px] h-[93px]">
          <div className={styles.navbar_container}>
            <Navbar />
          </div>
        </div>
      ) : (
        staticFlag && <CustomHeader
          data={headerData.chat}
          isSettingPanel={true}
        />
      )}
      <div className="flex justify-between items-start relative">
        <div className="main-body w-full">{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default GuestLayout;
