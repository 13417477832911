//file name: _app.js
//description: entry point of project
//author: TomExMachina
import { createPagesBrowserClient } from '@supabase/auth-helpers-nextjs'
import {
  SessionContextProvider,
  useSessionContext,
} from '@supabase/auth-helpers-react'

import { Lekton } from 'next/font/google'
import { ConfigProvider } from 'antd'
import { useState, useEffect } from 'react'
import { Provider } from 'react-redux'

import '@/styles/globals.css'
import SEO from '@/common/components/seo/SEO'
import { store } from '@/redux/store'
import WebSocketProvider from '../context/SharedWorker'
import GuestLayout from '@/layouts/guestLayout/GuestLayout'
import { useRouter } from 'next/router'
import { Toaster, toast } from 'react-hot-toast'
import WaitlistModal from '@/common/components/waitlistModal/WaitlistModal'
import { DevSupport } from '@react-buddy/ide-toolbox-next'
import { ComponentPreviews, useInitial } from '@/dev'
import GlobalAccessDeniedModal from '../components/GlobalAccessDeniedModal'

const lekton = Lekton({
  weight: '400',
  subsets: ['latin'],
})

export default function App({ Component, pageProps }) {
  const [supabaseClient] = useState(() => createPagesBrowserClient({
    supabaseUrl: 'https://db.firesight.ai',
    supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1tdGttaXRkaHFqbWZtbWxsdmV6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODMxNTUzMjgsImV4cCI6MTk5ODczMTMyOH0.4FEYh4c7aHuvDimQUl8O4y_OVQ0Dxiu9lmViJX-er1I',
  }))

  const [initialSession, setInitialSession] = useState(null)
  const router = useRouter()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        const data = JSON.parse(localStorage?.getItem('sb-mmtkmitdhqjmfmmllvez-auth-token'))
        setInitialSession(data)
      } catch (e) {
        console.error(e)
      }
    }
  }, [])

  useEffect(() => {
    if (!router.isReady) return

    const { error, error_code, error_description } = router.query

    if (error) {
      const decodedDescription = decodeURIComponent(error_description || 'An error occurred.')
      toast.error(`Error ${error_code}: ${decodedDescription}`, {
        style: {
          backgroundColor: '#080A16',
          borderColor: '#181A26',
          borderWidth: '1px',
          color: 'white',
        }
      })
    }
  }, [router.isReady, router.query])

  const MainLayout = Component.Layout || GuestLayout
  const isAuthorizeAppPage = router.asPath.startsWith('/authorize_app/')

  if (isAuthorizeAppPage) {
    return (
      <SessionContextProvider
        supabaseClient={supabaseClient}
        initialSession={pageProps.initialSession ?? initialSession}
      >
        <DevSupport ComponentPreviews={ComponentPreviews}
                    useInitialHook={useInitial}
        >
          <Component {...pageProps} />
        </DevSupport>
      </SessionContextProvider>
    )
  }

  return (
    <SessionContextProvider
      supabaseClient={supabaseClient}
      initialSession={pageProps.initialSession ?? initialSession}
    >
      <DevSupport ComponentPreviews={ComponentPreviews}
                  useInitialHook={useInitial}
      >
        <WebSocketProvider>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: lekton,
              },
            }}
          >
            <SEO />
            <Provider store={store}>
              <div className="cognisant-container">
                <div className="shineBg_body_bottom_right"></div>
                <div className="shineBg_body_top_left"></div>
                <div>
                  <MainLayout>
                    <Component {...pageProps} />
                  </MainLayout>
                </div>
              </div>
              <GlobalAccessDeniedModal />
              <Toaster />
            </Provider>
          </ConfigProvider>
        </WebSocketProvider>
      </DevSupport>
    </SessionContextProvider>
  )
}
